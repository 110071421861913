<div class="container">
  <div *ngIf="!error" class="spinner-div">
    <div class="spinner-border"></div>
    <div class="spinner-text">Loading..</div>
  </div>
  <div *ngIf="error" class="error-div">
    <span>Sorry we are facing an error at this time</span>
    <button class="retry-button" (click)="redirect()">Try again</button>
  </div>
</div>
