import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MyHttpClient } from '../http-client.service';

declare var MyFunction;

function getToken() {
  try {
    return MyFunction && MyFunction.getAuthToken();
  } catch {
    return "";
  }
}

@Component({
  selector: 'app-shell-card-registration',
  templateUrl: './shell-card-registration.component.html',
  styleUrls: ['./shell-card-registration.component.css']
})
export class ShellCardRegistrationComponent implements OnInit {
  private accessTokenAPI: string = `${environment.captainServer}/api/shell/token`;
  private shellRedirectUrl: string = environment.shellRedirectURL;
  public error: string | undefined = "true";

  constructor(private http: MyHttpClient) { }

  ngOnInit() {
    this.redirect();
  }

  redirectToShellPage(token: string) {
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", this.shellRedirectUrl);
    form.setAttribute("style", "display:none");

    const hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", "access_token");
    hiddenField.setAttribute("value", token);

    form.appendChild(hiddenField);
    document.body.appendChild(form);

    form.submit();
  }

  async redirect() {
    try {
      this.error = undefined;
      await this.generateTokenAndRedirect();
    } catch (error) {
      this.error = error;
    }
  }

  async generateTokenAndRedirect() {
    const { token: accessToken } = await this.http.executeGetRequest(this.accessTokenAPI, getToken())
      .toPromise() as any;
    this.redirectToShellPage(accessToken);
  }
}
