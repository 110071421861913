import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-power-pass',
  templateUrl: './power-pass-component.html',
  styleUrls: ['./power-pass-component.css']
})
export class PowerPassComponent implements OnInit {
    public powerPass1 : string;
    public powerPass2 : string;
    public powerPass3 : string;
    public powerPass4 : string;
    public powerPass5 : string;
    public introText1 : string;
    public introText2 : string;
    public buyPowerPass1 : string;
    public buyPowerPass2 : string;
    public buyPowerPass22 : string;
    public passApply1 : string;
    public passApply2 : string;
    public passExpires1 : string;
    public passExpires2 : string
    public passToChoose : string;
  ngOnInit() : void {
    this.updateValues();
  }

  updateValues() {
    this.powerPass1 = '/assets/powerpass/PASS-EXPLAINER-STEP-1.gif',
    this.powerPass2 = '/assets/powerpass/PASS-EXPLAINER-STEP-2.gif',
    this.powerPass3 = '/assets/powerpass/PASS-EXPLAINER-STEP-3.gif',
    this.powerPass4 = '/assets/powerpass/PASS-EXPLAINER-STEP-4.gif',
    this.powerPass5 = '/assets/powerpass/PASS-EXPLAINER-STEP-5.gif',
    this.introText1 = "Power Pass is a subscription offered by Rapido, providing you with a fixed discount on every ride." 
    this.introText2 = "Enjoy hassle-free transportation with automatic application of discounts and exclusive benefits for the whole month. Get your power pass and start saving today!",
    this.buyPowerPass1 = 'Check out the list of Power passes for your preferred service. You can compare and select the pass that suits your needs best.',
    this.buyPowerPass2 = 'Select the pass you wish to purchase and click on “Buy Pass” to Proceed to Pay.',
    this.buyPowerPass22 = 'Once the payment is complete, your pass will be activated & ready to use from the next ride.',
    this.passApply1 = 'Next time you book a ride, your pass will automatically apply for your preferred service. Your Discounted fare will appear while booking.',
    this.passApply2 = 'You can easily track your pass benefits, check the expiry date and plan your next purchase.',
    this.passExpires1 = 'With the Auto-Renew option, when you pay using your Rapido wallet, your pass will get renewed automatically at the end of the validity itself.',
    this.passExpires2 = 'Or you can Renew the pass, when it expires by any other payment mode of your choice.',
    this.passToChoose = 'We offer service-specific passes and specially curated passes for you, based on your frequency of commute and duration.'
  }
}