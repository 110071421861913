<!-- <img src="https://files.slack.com/files-pri/T0EKHQHK2-FLPDZU89M/artboard_copy.png"> -->
<!-- <head>
  <script>
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '857628661017736');
    fbq('track', 'PageView');
  </script>
  <noscript><img height=“1” width=“1" style=“display:none”
    src=“https://www.facebook.com/tr?id=857628661017736&ev=PageView&noscript=1"
  /></noscript>
</head> -->
<div class="wrapper">
  <div class="parent">
    <div class="banner">
      <div class="imageDiv"></div>
    </div>
    <div class="container">

      <div class="row margin-diff" style="margin-top: 15px;">
        <div class="col-2">
            <div class="imageDiv-whatsapp imageDiv-whatsapp-green">
              </div>
        </div>
        <div class="col-10">
          <p class="bold">
              Send us a WhatsApp message to 7411412413 to book your ride
          </p>
        </div>
      </div>
      <div class="row margin-diff">
        <div class="col-2">
            <div class="imageDiv-location">
              </div>
        </div>
        <div class="col-10">
          <p>
              Let us track you via GPS. Share your pickup location
          </p>
        </div>
      </div>
      <div class="row margin-diff">
        <div class="col-2">
            <div class="imageDiv-rs">
              </div>
        </div>
        <div class="col-10">
          <p>
              All rides booked through WhatsApp to be paid in cash
          </p>
        </div>
        </div>

      </div>
      <div class="row checkbox-alignment">
        <div class="col-1">
          <input style="width: 18px; height: 18px" [(ngModel)]="optedIn" type="checkbox">
        </div>
        <div class="col-10 text-alignment">I accept to receive Rapido booking communication messages on WhatsApp.</div>
      </div>
    <div [ngStyle]="{'cursor' : optedIn ? 'pointer' : 'not-allowed'}" [class.button-whatsapp-disabled]="!optedIn" class="row button-whatsapp" (click)="onWhatsappClick()">
      <div class="whatsapp-text">
          <div class="icon-sizing" [class.imageDiv-whatsapp]="optedIn" [class.imageDiv-whatsapp-white]="optedIn" [class.disabled-whatsapp]="!optedIn">
          </div>
          Type 'Hi' to Request for Rapido
      </div>
    </div>
  </div>
  <div>
    <!-- <script>
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '857628661017736');
      fbq('track', 'PageView');
    </script>
    <noscript><img height=“1” width=“1" style=“display:none”
      src=“https://www.facebook.com/tr?id=857628661017736&ev=PageView&noscript=1"
    /></noscript> -->
  </div>
</div>