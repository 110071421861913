<div class="wrapper">
      <div class="page">
        <div class="banner">
            <img src="../../assets/powerpass/BannerPassExplainer.svg">
        </div>
      <div class="content">
        <div class="service-container">
                <p class="pass-priveleges center">ENJOY THESE PASS BENEFITS</p>
            <div class="service-icons">
                <div class="svg-icon pass-saving"></div>
                <div class="svg-icon service"></div>
                <div class="svg-icon auto-apply"></div>      
            </div>
            <div class="service-icons">
                <div class="service-icon-labels">Guaranteed discounts</div>
                <div class="service-icon-labels">Choose your preferred service</div>
                <div class="service-icon-labels">Automatically applied</div>
            </div>
        </div>
        <div class="">
            <div class="power-pass-question">
                <p class="heading">WHAT IS POWER PASS?</p>
            </div>
            <small class="normal-text">
                {{introText1}}<br><br>{{introText2}}
            </small>
            <hr class="hr-styled">
            <div class="buy-power-pass">
                <p class="heading">HOW CAN I BUY A POWER PASS?</p>
            </div>
            <p class="step-1">STEP 1</p>
            <small class="step-1-text">{{buyPowerPass1}}</small>
            <div class="gif" style="top: 679px">
                <img src={{powerPass1}}>  
            </div>
            <p class="step-2">STEP 2</p>
            <small class="step-2-text">{{buyPowerPass2}}</small>
            <div class="gif" style="top: 1003px">
                  <img src={{powerPass2}}>  
            </div>
            <small class="step-2-text-2">{{buyPowerPass22}}</small>
            <hr class="hr-styled-2">
            <div class="power-pass-apply">
                <p class="heading">HOW IS POWER PASS APPLIED?</p>
            </div>
            <small class="pass-apply-1">{{passApply1}}</small>
            <div class="gif" style="top: 1487px">
                <img src={{powerPass3}}>  
            </div>
            <small class="pass-apply-2">{{passApply2}}</small>
            <div class="gif" style="top: 1771px">
                <img src={{powerPass4}}>  
            </div>
            <div class="pass-expires">
                <p class="heading">what if THE pass expires ?</p>
            </div>
            <small class="pass-expires-1">{{passExpires1}}<br><br>{{passExpires2}}</small>
            <div class="gif" style="top: 2235px">
                <img src={{powerPass5}}>  
            </div>
            <div class="passes-to-choose">
                <p class="heading">PASSES TO CHOOSE FROM</p>
            </div>
            <small class="passes-to-choose-1">{{passToChoose}}</small>
            <div class="last-image">
                <img src="../../assets/powerpass/power-pass-save.png">  
            </div>
        </div>
    </div>
</div>