/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-incentive.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../card-separator/card-separator.component.ngfactory";
import * as i3 from "../card-separator/card-separator.component";
import * as i4 from "../download-app/download-app.component.ngfactory";
import * as i5 from "../download-app/download-app.component";
import * as i6 from "../info-header/info-header.component.ngfactory";
import * as i7 from "../info-header/info-header.component";
import * as i8 from "@angular/common";
import * as i9 from "./view-incentive.component";
import * as i10 from "./view-incentive.service";
import * as i11 from "@angular/router";
var styles_ViewIncentiveComponent = [i0.styles];
var RenderType_ViewIncentiveComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewIncentiveComponent, data: {} });
export { RenderType_ViewIncentiveComponent as RenderType_ViewIncentiveComponent };
function View_ViewIncentiveComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-card-separator", [], null, null, null, i2.View_CardSeparatorComponent_0, i2.RenderType_CardSeparatorComponent)), i1.ɵdid(2, 638976, null, 0, i3.CardSeparatorComponent, [], { data: [0, "data"], type: [1, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.data.incentiveType; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ViewIncentiveComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "height-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-download-app", [], null, null, null, i4.View_DownloadAppComponent_0, i4.RenderType_DownloadAppComponent)), i1.ɵdid(2, 114688, null, 0, i5.DownloadAppComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-info-header", [], null, null, null, i6.View_InfoHeaderComponent_0, i6.RenderType_InfoHeaderComponent)), i1.ɵdid(4, 114688, null, 0, i7.InfoHeaderComponent, [], { header: [0, "header"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewIncentiveComponent_1)), i1.ɵdid(6, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "margin-top-20"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.header; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.cardData; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_ViewIncentiveComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-view-incentive", [], null, null, null, View_ViewIncentiveComponent_0, RenderType_ViewIncentiveComponent)), i1.ɵdid(1, 114688, null, 0, i9.ViewIncentiveComponent, [i10.ViewIncentiveService, i11.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewIncentiveComponentNgFactory = i1.ɵccf("app-view-incentive", i9.ViewIncentiveComponent, View_ViewIncentiveComponent_Host_0, {}, {}, []);
export { ViewIncentiveComponentNgFactory as ViewIncentiveComponentNgFactory };
