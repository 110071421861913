import { environment } from "src/environments/environment";

export class HelperService {
  static debounce = (func, delay = 3000) => {
    let debounceTimer;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      };
  }
  static getQueryString(find: any = {}, select: any = []) {
      const keywords = Object.keys(find);
      const specialOperator = [
        'sort',
        'limit',
        'offset',
      ];
      let queryString = keywords.reduce((acc, keyword, index) => {
        const findCondition = find[keyword];
        if (specialOperator.includes(keyword)) {
          acc += `${keyword}=${findCondition}`;
        } else if (findCondition instanceof Array) {
          acc += `${keyword}__in=${findCondition.join(',')}`;
        } else if (typeof findCondition === 'object' ) {
          acc += this.makeConditionQuery(keyword, findCondition);
        } else {
          acc += `${keyword}__eq=${findCondition}`;
        }
        acc += `&`;
        return acc;
      }, '');
      if (select && select.length) {
        queryString += `select=${select.join(',')}`;
      }
      return queryString;
  }
  static makeConditionQuery(parent: string, condition: any) {
    const conditionOperators = Object.keys(condition);
    const conditionQueryString = conditionOperators.map(cond => {
      return `${parent}__${cond}=${condition[cond]}`;
    });
    return conditionQueryString.join('&');
  }
  static timestampToReadableFormat(timeStamp: Date) {
    const date = new Date(timeStamp);
    const timeString = date.toLocaleTimeString().split(':').splice(0, 2).join(':')
    return `${timeString} ${HelperService.dateString(date)}`;
  }

  static isValidDate(date: Date) {
    return (date && date.getTime()) ? true : false;
  }

  static setCookie(data: any = {}) {
    Object.keys(data).map(key => {
      let value = data[key];
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }
      document.cookie = `${key}=${value}`;
    });
  }

  static regexString(string) {
    return `${string}$/i`;
  }
  static roundNumber(num) {
    // const roundTwoDecimal = (num) => {
      return Math.round(num * 100) / 100;
    //  };
  }
  static accessCookie(cookieName) {
          const name = cookieName + '=';
          const allCookieArray = document.cookie.split(';');
          for (let i = 0; i < allCookieArray.length; i++) {
            const temp = allCookieArray[i].trim();
            if (temp.indexOf(name) === 0) {
              return temp.substring(name.length, temp.length);
            }
          }
          return '';
        }

  static dateString(date: any) {
    if (date && Date.parse(date)) {
      return new Date(date).toDateString();
    }
    return 'N/A';
  }

  static getCurrentUrl() {
    return window.location.href;
  }

  static getCurrentPath() {
    return window.location.pathname;
  }

  static routeParams(): any {
    return decodeURIComponent(window.location.search.substring(1)).split('&')
      .reduce((acc, keyValue) => {
        const data = keyValue.split('=');
        acc[data[0]] = data[1];
        return acc;
        }, {});
  }
}
