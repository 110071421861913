/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rating-info-webview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./rating-info-webview.component";
var styles_RatingInfoWebview = [i0.styles];
var RenderType_RatingInfoWebview = i1.ɵcrt({ encapsulation: 0, styles: styles_RatingInfoWebview, data: {} });
export { RenderType_RatingInfoWebview as RenderType_RatingInfoWebview };
export function View_RatingInfoWebview_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Page Not Found"], ["class", "imageUrl"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.pageUrl, ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_RatingInfoWebview_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "rating-info", [], null, null, null, View_RatingInfoWebview_0, RenderType_RatingInfoWebview)), i1.ɵdid(1, 114688, null, 0, i2.RatingInfoWebview, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RatingInfoWebviewNgFactory = i1.ɵccf("rating-info", i2.RatingInfoWebview, View_RatingInfoWebview_Host_0, {}, {}, []);
export { RatingInfoWebviewNgFactory as RatingInfoWebviewNgFactory };
