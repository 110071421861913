import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-software-licence',
  templateUrl: './software-licence.component.html',
  styleUrls: ['./software-licence.component.css']
})
export class SoftwareLicenceComponent implements OnInit {
  ngOnInit() : void {
  }
}
