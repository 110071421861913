<div class="wrapper">
    <div>
        <div class="title">Dagger 2</div>
        <div class="content">
            <div class="copyright">
                Copyright 2012 The Dagger Authors
                Licensed under the Apache License, Version 2.0 (the "License");
                you may not use this file except in compliance with the License.
                You may obtain a copy of the License at
            </div>
            <div class="licence-url">
                http://www.apache.org/licenses/LICENSE-2.0
            </div>
            <div class="info">
                Unless required by applicable law or agreed to in writing, software
                distributed under the License is distributed on an "AS IS" BASIS,
                WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
                See the License for the specific language governing permissions and
                limitations under the License.
            </div>
        </div>
    </div>
    <div>
        <div class="title">Otto - An event bus by Square</div>
        <div class="content">
            <div class="copyright">
                Copyright 2012 Square, Inc.Copyright 2010 Google, Inc.
                Licensed under the Apache License, Version 2.0 (the "License");
                you may not use this file except in compliance with the License.
                You may obtain a copy of the License at
            </div>
            <div class="licence-url">
                http://www.apache.org/licenses/LICENSE-2.0
            </div>
            <div class="info">
                Unless required by applicable law or agreed to in writing, software
                distributed under the License is distributed on an "AS IS" BASIS,
                WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
                See the License for the specific language governing permissions and
                limitations under the License.
            </div>
        </div>
    </div>
    <div>
        <div class="title">Retrofit</div>
        <div class="content">
            <div class="copyright">
                Copyright 2013 Square, Inc.
                Licensed under the Apache License, Version 2.0 (the "License");
                you may not use this file except in compliance with the License.
                You may obtain a copy of the License 
            </div>
            <div class="licence-url">
                http://www.apache.org/licenses/LICENSE-2.0
            </div>
            <div class="info">
                Unless required by applicable law or agreed to in writing, software
                distributed under the License is distributed on an "AS IS" BASIS,
                WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
                See the License for the specific language governing permissions and
                limitations under the License.
            </div>
        </div>
    </div>
    <div>
        <div class="title">OkHttp</div>
        <div class="content">
            <div class="copyright">
                Licensed under the Apache License, Version 2.0 (the "License");
                you may not use this file except in compliance with the License.
                You may obtain a copy of the License at
            </div>
            <div class="licence-url">
                http://www.apache.org/licenses/LICENSE-2.0
            </div>
            <div class="info">
                Unless required by applicable law or agreed to in writing, software
                distributed under the License is distributed on an "AS IS" BASIS,
                WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
                See the License for the specific language governing permissions and
                limitations under the License.
            </div>
        </div>
    </div>
    <div>
        <div class="title">google-gson</div>
        <div class="content">
            <div class="copyright">
                Copyright 2008 Google Inc.
                Licensed under the Apache License, Version 2.0 (the "License");
                you may not use this file except in compliance with the License.
                You may obtain a copy of the License at
            </div>
            <div class="licence-url">
                http://www.apache.org/licenses/LICENSE-2.0
            </div>
            <div class="info">
                Unless required by applicable law or agreed to in writing, software
                distributed under the License is distributed on an "AS IS" BASIS,
                WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
                See the License for the specific language governing permissions and
                limitations under the License.
            </div>
        </div>
    </div>
    <div>
        <div class="title">RxAndroid: Reactive Extensions for Android</div>
        <div class="content">
            <div class="copyright">
                Copyright 2015 The RxAndroid authors.
                Licensed under the Apache License, Version 2.0 (the "License");
                you may not use this file except in compliance with the License.
                You may obtain a copy of the License at
            </div>
            <div class="licence-url">
                http://www.apache.org/licenses/LICENSE-2.0
            </div>
            <div class="info">
                Unless required by applicable law or agreed to in writing, software
                distributed under the License is distributed on an "AS IS" BASIS,
                WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
                See the License for the specific language governing permissions and
                limitations under the License.
            </div>
        </div>
    </div>
    <div>
        <div class="title">RxBinding</div>
        <div class="content">
            <div class="copyright">
                Copyright © 2015 Jake Wharton
                Licensed under the Apache License, Version 2.0 (the "License");
                you may not use this file except in compliance with the License.
                You may obtain a copy of the License at
            </div>
            <div class="licence-url">
                http://www.apache.org/licenses/LICENSE-2.0
            </div>
            <div class="info">
                Unless required by applicable law or agreed to in writing, software
                distributed under the License is distributed on an "AS IS" BASIS,
                WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
                See the License for the specific language governing permissions and
                limitations under the License.
            </div>
        </div>
    </div>
    <div>
        <div class="title">TokenAutoComplete</div>
        <div class="content">
            <div class="copyright">
                Copyright © 2013, 2014 splitwise, Wouter Dullaert
                Licensed under the Apache License, Version 2.0 (the "License");
                you may not use this file except in compliance with the License.
                You may obtain a copy of the License at
            </div>
            <div class="licence-url">
                http://www.apache.org/licenses/LICENSE-2.0
            </div>
            <div class="info">
                Unless required by applicable law or agreed to in writing, software
                distributed under the License is distributed on an "AS IS" BASIS,
                WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
                See the License for the specific language governing permissions and
                limitations under the License.
            </div>
        </div>
    </div>
    <div>
        <div class="title">SmoothProgressBar</div>
        <div class="content">
            <div class="copyright">
                Copyright 2014 Antoine Merle
                Licensed under the Apache License, Version 2.0 (the "License");
                you may not use this file except in compliance with the License.
                You may obtain a copy of the License at
            </div>
            <div class="licence-url">
                http://www.apache.org/licenses/LICENSE-2.0
            </div>
            <div class="info">
                Unless required by applicable law or agreed to in writing, software
                distributed under the License is distributed on an "AS IS" BASIS,
                WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
                See the License for the specific language governing permissions and
                limitations under the License.
            </div>
        </div>
    </div>
    <div>
        <div class="title">Spinner DatePicker</div>
        <div class="content">
            <div class="copyright">
                Copyright 2017 AOSP, David Rawson
                Licensed under the Apache License, Version 2.0 (the "License");
                you may not use this file except in compliance with the License.
                You may obtain a copy of the License at
            </div>
            <div class="licence-url">
                http://www.apache.org/licenses/LICENSE-2.0
            </div>
            <div class="info">
                Unless required by applicable law or agreed to in writing, software
                distributed under the License is distributed on an "AS IS" BASIS,
                WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
                See the License for the specific language governing permissions and
                limitations under the License.
            </div>
        </div>
    </div>
    <div>
        <div class="title">MaterialIntroView [Beta]</div>
        <div class="content">
            <div class="copyright">
                Copyright 2015 Mert Şimşek.
                Licensed under the Apache License, Version 2.0 (the "License");
                you may not use this file except in compliance with the License.
                You may obtain a copy of the License at
            </div>
            <div class="licence-url">
                http://www.apache.org/licenses/LICENSE-2.0
            </div>
            <div class="info">
                Unless required by applicable law or agreed to in writing, software
                distributed under the License is distributed on an "AS IS" BASIS,
                WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
                See the License for the specific language governing permissions and
                limitations under the License.
            </div>
        </div>
    </div>
    <div>
        <div class="title">MaterialChipsInput</div>
        <div class="content">
            <div class="copyright">
                Copyright 2017 pchmn
                Licensed under the Apache License, Version 2.0 (the "License");
                you may not use this file except in compliance with the License.
                You may obtain a copy of the License at
            </div>
            <div class="licence-licence-url">
                http://www.apache.org/licenses/LICENSE-2.0
            </div>
            <div class="info">
                Unless required by applicable law or agreed to in writing, software
                distributed under the License is distributed on an "AS IS" BASIS,
                WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
                See the License for the specific language governing permissions and
                limitations under the License.
            </div>
        </div>
    </div>
</div>