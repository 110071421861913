/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shell-card-registration.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./shell-card-registration.component";
import * as i4 from "../http-client.service";
var styles_ShellCardRegistrationComponent = [i0.styles];
var RenderType_ShellCardRegistrationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShellCardRegistrationComponent, data: {} });
export { RenderType_ShellCardRegistrationComponent as RenderType_ShellCardRegistrationComponent };
function View_ShellCardRegistrationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "spinner-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "spinner-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "spinner-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading.."]))], null, null); }
function View_ShellCardRegistrationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "error-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sorry we are facing an error at this time"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "retry-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.redirect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Try again"]))], null, null); }
export function View_ShellCardRegistrationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShellCardRegistrationComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShellCardRegistrationComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.error; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.error; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ShellCardRegistrationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shell-card-registration", [], null, null, null, View_ShellCardRegistrationComponent_0, RenderType_ShellCardRegistrationComponent)), i1.ɵdid(1, 114688, null, 0, i3.ShellCardRegistrationComponent, [i4.MyHttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShellCardRegistrationComponentNgFactory = i1.ɵccf("app-shell-card-registration", i3.ShellCardRegistrationComponent, View_ShellCardRegistrationComponent_Host_0, {}, {}, []);
export { ShellCardRegistrationComponentNgFactory as ShellCardRegistrationComponentNgFactory };
