import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelperService } from './helper.service';

@Injectable()
export class MyHttpClient {
  public cookies: any;
  public userCookie: any;
  public userIdCookie: any;
  public userId: any;
  private _headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(
    private http: HttpClient) { }

  setHeaderData() {
    //populates data for headers with latest cookies
    this.cookies = document.cookie.split(";");
    if (this.cookies) {
      this.userCookie = HelperService.accessCookie('user');
      // this.userCookie = this.cookies.find(function (item) {
      //   return item.includes('_id');
      // })
    }
    if (this.userCookie) {
      this.userIdCookie = Object.values(JSON.parse(this.userCookie))[0];
    }
    if (this.userIdCookie) {
      this.userId = '{"_id":"' + this.userIdCookie + '"}';
    }
  }


  get(url) {
    this.setHeaderData();
    const headers = this._headers.append('user', this.userId ? this.userId : '')
      .append('authorization', 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI1YzliMjAxMGQ3OTRiOTMwMzdlMmUyNDciLCJpYXQiOjE1NTQ5NjU3NDgsImV4cCI6MTU1NDk5NDU0OH0.YYghR3VNuY2hYLNeHe6nKA4odSFvMMYDUcvKJ97A_gI')
      .append('Expires', '0')
      .append('Cache-control', 'no-cache')
      .append('Cache-control', 'no-store')
      .append('Pragma', 'no-cache');

    return this.http.get(url, { headers: headers });
  }

  post(url, data) {
    this.setHeaderData();
    const headers = this._headers.append('user', this.userId ? this.userId : '')
      .append('Expires', '0')
      .append('Cache-control', 'no-cache')
      .append('Cache-control', 'no-store')
      .append('Pragma', 'no-cache');
    return this.http.post(url, data, { headers: headers });
  }

  executeGetRequest(url, token, lang = "") {
    const headers = this._headers.append('authorization', `Bearer ${token}` || '')
      .append('Expires', '0')
      .append('Cache-control', 'no-cache')
      .append('Cache-control', 'no-store')  //need to check this
      .append('Pragma', 'no-cache')
      .append('lang', lang);

    return this.http.get(url, { headers });
  }

  executePostRequest(url, data, config = {}, addHeaders = {}, token, lang) {
    const headers = this._headers.append('authorization', `Bearer ${token}` || '')
      .append('Expires', '0')
      .append('Cache-control', 'no-cache')
      .append('Cache-control', 'no-store')  //need to check this
      .append('Pragma', 'no-cache')
      .append('lang', lang);

    if (addHeaders) {
      Object.keys(addHeaders).forEach(key => {
        headers.append(key, addHeaders[key]);
      });
    }
    return this.http.post(url, data, { headers, ...config });
  }
}
