/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-template.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./generic-template.component";
import * as i4 from "@angular/router";
var styles_GenericTemplateComponent = [i0.styles];
var RenderType_GenericTemplateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericTemplateComponent, data: {} });
export { RenderType_GenericTemplateComponent as RenderType_GenericTemplateComponent };
function View_GenericTemplateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "asset-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "asset"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.darkMode && _co.configuration.darkAssetUrl) ? _co.configuration.darkAssetUrl : _co.configuration.assetUrl); _ck(_v, 1, 0, currVal_0); }); }
function View_GenericTemplateComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "title"], ["id", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.renderTitle(_co.configuration.titleText); _ck(_v, 1, 0, currVal_0); }); }
function View_GenericTemplateComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "subtext"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.configuration.description; _ck(_v, 1, 0, currVal_0); }); }
function View_GenericTemplateComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "vp-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "vp-icon-asset"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.darkMode && _v.parent.context.$implicit.darkIconUrl) ? _v.parent.context.$implicit.darkIconUrl : _v.parent.context.$implicit.iconUrl); _ck(_v, 1, 0, currVal_0); }); }
function View_GenericTemplateComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "value-proposition"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericTemplateComponent_7)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "vp-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "vp-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "vp-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.hasVpIcons && _v.context.$implicit.iconUrl); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.description; _ck(_v, 7, 0, currVal_2); }); }
function View_GenericTemplateComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "value-propositions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericTemplateComponent_6)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.configuration.valuePropositions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_GenericTemplateComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "leading-icon flex-align-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "link-icon-asset"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.darkMode && _co.configuration.link.darkLeadingIcon) ? _co.configuration.link.darkLeadingIcon : _co.configuration.link.leadingIcon); _ck(_v, 1, 0, currVal_0); }); }
function View_GenericTemplateComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "trailing-icon flex-align-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "link-icon-asset"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.darkMode && _co.configuration.link.darkTrailingIcon) ? _co.configuration.link.darkTrailingIcon : _co.configuration.link.trailingIcon); _ck(_v, 1, 0, currVal_0); }); }
function View_GenericTemplateComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "link-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericTemplateComponent_9)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "link-text"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericTemplateComponent_10)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.configuration.link.leadingIcon; _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.configuration.link.trailingIcon; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.configuration.link.url, ""); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.configuration.link.displayText; _ck(_v, 4, 0, currVal_2); }); }
function View_GenericTemplateComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "bouncing-icon"], ["src", "../../assets/expand_more.svg"]], null, null, null, null, null))], null, null); }
function View_GenericTemplateComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "bouncing-icon"], ["src", "../../assets/chevron_right.svg"]], null, null, null, null, null))], null, null); }
function View_GenericTemplateComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "bounce"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericTemplateComponent_12)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericTemplateComponent_13)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.darkMode; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.darkMode; _ck(_v, 4, 0, currVal_1); }, null); }
function View_GenericTemplateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "imageUrl"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericTemplateComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericTemplateComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericTemplateComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericTemplateComponent_5)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericTemplateComponent_8)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericTemplateComponent_11)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.configuration.assetUrl; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.configuration.titleText; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.configuration.description; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.configuration.valuePropositions; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.hasLink; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.showScrollIndication; _ck(_v, 14, 0, currVal_5); }, null); }
function View_GenericTemplateComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "../../assets/template-error-screen.svg"]], null, null, null, null, null))], null, null); }
export function View_GenericTemplateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "template"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "dark-mode": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericTemplateComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericTemplateComponent_14)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "template"; var currVal_1 = _ck(_v, 2, 0, _co.darkMode); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (!_co.error && _co.configuration); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.error; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_GenericTemplateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-template", [], null, null, null, View_GenericTemplateComponent_0, RenderType_GenericTemplateComponent)), i1.ɵdid(1, 114688, null, 0, i3.GenericTemplateComponent, [i4.ActivatedRoute, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericTemplateComponentNgFactory = i1.ɵccf("app-generic-template", i3.GenericTemplateComponent, View_GenericTemplateComponent_Host_0, {}, {}, []);
export { GenericTemplateComponentNgFactory as GenericTemplateComponentNgFactory };
