import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CaptainSubscriptionService } from './captain-subscriptions.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

const VARIABLE_COMMISSION = "variableCommission";
@Component({
  selector: 'app-captain-subscriptions',
  templateUrl: './captain-subscriptions.component.html',
  styleUrls: ['./captain-subscriptions.component.css']
})
export class CaptainSubscriptionsComponent implements OnInit {
  constructor(
    private sanitizer: DomSanitizer,
    private captainSubscriptionService: CaptainSubscriptionService,
    private route: ActivatedRoute,
  ) {
  }

  public showBuyBox: boolean;
  public buyStatus: string;
  public buyInitiated: boolean;
  public activeSubscriptionExists: boolean;
  public nextSubsPurchased: boolean;
  public showTC: boolean;
  public showLoader: boolean;
  public loaderText: string;
  public subscriptionDetailsStatus: string;
  public subscriptionDetails: any;
  public subscriptionBuyDetails: any;
  public subscriptionAvailable: boolean;
  public paymentStatus: any;
  public lang: string;
  public errorPageTexts: any;
  safeSrc: SafeResourceUrl;
  public canBuyNextSubscription: boolean;
  public tncs: string[];
  public isCab: boolean = false;
  public bannerForCab: string;

  private player: any;

  ngOnInit() {
    this.lang = this.route.snapshot.paramMap.get('lang');
    this.lang = this.lang ? this.lang : `${environment.subscriptionDefaultLanguage}`;
    this.bannerForCab = `/assets/subscription/bannerForCab/${this.lang}.svg`;
    this.subscriptionDetailsStatus = "";
    this.showBuyBox = false;
    this.buyStatus = "";
    this.buyInitiated = false;
    this.showTC = false;
    this.showLoader = false;
    this.errorPageTexts = {
      errorLoadingText: this.captainSubscriptionService.getErrorLoadingText(this.lang),
      errorPurchasingText: this.captainSubscriptionService.getErrorPurchasingText(this.lang),
      tryAgainText: this.captainSubscriptionService.getTryAgainText(this.lang),
      tryAgainButtonText: this.captainSubscriptionService.getTryAgainButtonText(this.lang),
      goBackButtonText: this.captainSubscriptionService.getGoBackButtonText(this.lang)
    };

    this.paymentStatus = this.captainSubscriptionService.getPaymentStatus();
    if (this.paymentStatus && this.paymentStatus.razor_pay_transaction_status != "") {
      if (this.paymentStatus.razor_pay_transaction_status == "success") {
        this.buy(this.paymentStatus.captain_subscription_id, this.paymentStatus.razor_pay_transaction_id, this.paymentStatus.transaction_id);
      } else if (this.paymentStatus.razor_pay_transaction_status == "failure") {
        this.buyInitiated = true;
        this.buyStatus = "ERROR";
        this.captainSubscriptionService.publishEvent("subscription_purchase",
          { status: "FAILED_RAZORPAY", failureReason: "Wallet recharge failed" });
      }
    } else {
      this.handleLoader(this.captainSubscriptionService.getLoadingPlanText(this.lang));
      this.captainSubscriptionService.getSubscriptionDetails(this.lang).subscribe(res => {
        this.subscriptionDetailsStatus = "SUCCESS";
        this.subscriptionDetails = res['data'];
        this.setupYoutubeVideo(this.subscriptionDetails.genericDetails.videoLink);
        this.handleLoader("");
        this.activeSubscriptionExists = this.subscriptionDetails.activeSubscription;
        this.subscriptionAvailable = this.subscriptionDetails.availableSubscriptions;
        this.nextSubsPurchased = this.subscriptionDetails.nextSubscriptionPurchased;
        const hasboughtSubscription = this.nextSubsPurchased && this.activeSubscriptionExists;
        this.canBuyNextSubscription = !hasboughtSubscription && Boolean(this.subscriptionAvailable);
        this.tncs = this.getTncs();
        this.isCab = this.subscriptionAvailable && this.subscriptionDetails.availableSubscriptions[0].isCabSubscription ||
          this.activeSubscriptionExists && this.subscriptionDetails.activeSubscription.isCabSubscription;
        if (this.isCab) {
          this.subscriptionAvailable = !this.activeSubscriptionExists
          this.canBuyNextSubscription = !this.activeSubscriptionExists && this.subscriptionAvailable
        }
      }, error => {
        this.subscriptionDetailsStatus = "ERROR";
        this.handleLoader("");
      });

    }
  }

  getTncs(): string[] {
    const subscription = this.subscriptionDetails.activeSubscription || (this.subscriptionAvailable || [])[0];
    return subscription && subscription.tncs;
  }

  ngOnDestroy() {
    delete window['onYouTubeIframeAPIReady'];
  }

  onVideoStateChange = (event) => {
    switch (event.data) {
      case window['YT'].PlayerState.PLAYING:
        this.captainSubscriptionService.publishEvent("subscriptionPage_training", { position: "start" });
        break;
      case window['YT'].PlayerState.ENDED:
        this.captainSubscriptionService.publishEvent("subscriptionPage_training", { position: "end" });
        break;
    };
  };

  setupYoutubeVideo(videoLink: string) {
    this.setupYoutubeIframeAPI();
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(videoLink + "?enablejsapi=1&rel=0");
    window['onYouTubeIframeAPIReady'] = () => {
      this.player = new window['YT'].Player('player', {
        events: {
          'onStateChange': this.onVideoStateChange
        }
      });
    };
  }

  setupYoutubeIframeAPI() {
    if (window['YT']) {
      return;
    }

    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  handleLoader(text) {
    this.showLoader = !this.showLoader;
    this.loaderText = text;
  }

  buyPlan() {
    this.captainSubscriptionService.publishEvent("subscription_purchase_click");
    if (this.isCab) {
      this.showBuyBox = false;
      this.buy();
      return
    }
    this.showBuyBox = true;
  }

  cancelBuy() {
    this.showBuyBox = false;
  }

  async buy(subscriptionId = "", paymentId = "", transactionId = "") {
    this.handleLoader(this.captainSubscriptionService.getPurchasingPlanText(this.lang));
    this.buyInitiated = true;
    let id = subscriptionId || this.subscriptionDetails.availableSubscriptions[0].id;
    let res = await this.retryTransaction(id, paymentId, transactionId);
    let mode = paymentId === "" ? "RAPIDO" : "RAZORPAY";
    let eventDetails = this.getPurchaseEventDetails(res.data);

    if (res.funcStatus == "success") {
      this.buyStatus = "SUCCESS";
      this.subscriptionBuyDetails = res;
      this.showBuyBox = false;
      this.hideBackground("background-info", "none");
      this.captainSubscriptionService.publishEvent("subscription_purchase",
        { status: `SUCCESS_${mode}`, ...eventDetails });
    } else if (res.code == 402) {
      this.subscriptionBuyDetails = res.data;
      const eventData = {
        status: `FAILED_${mode}`, ...eventDetails,
        failureReason: paymentId === "" ? "Low wallet balance" : "Razorpay recharge failed"
      };
      if (this.paymentStatus && this.paymentStatus.razor_pay_transaction_status == "") {
        this.captainSubscriptionService.publishEvent("subscription_purchase", eventData);
        this.captainSubscriptionService.rechargeWallet(this.subscriptionDetails, this.subscriptionBuyDetails);
        this.hideBackground("background-info", "none");
      } else {
        this.captainSubscriptionService.publishEvent("subscription_purchase", eventData);
        this.buyStatus = "FAILED";
      }
    } else {
      this.captainSubscriptionService.publishEvent("subscription_purchase", {
        status: `FAILED_${mode}`, ...eventDetails, failureReason: "Technical error"
      });
      this.buyStatus = "ERROR";
      this.hideBackground("background-info", "none");
    }
    this.handleLoader("");
    this.showBuyBox = false;
  }

  getPurchaseEventDetails(response: any) {
    if (!response || !response.balance) {
      return {};
    }
    return {
      balance: response.balance.toFixed(2),
      firstTime: response.firstTime,
    };
  }

  async retryTransaction(subscriptionId = "", paymentId = "", transactionId = "") {
    let timeOut = 2000;
    let tryCount = 0;
    if (paymentId != "" && transactionId != "") {
      tryCount = parseInt(environment.subscriptionPurchaseRetryCount);
    }
    const timer = ms => new Promise(res => setTimeout(res, ms));

    let response;
    for (var i = 0; i <= tryCount; i++) {
      await this.purchaseSubscription(subscriptionId, paymentId, transactionId, i).then((data: object) => {
        response = { ...data, funcStatus: "success" };
      }, async (error: object) => {
        response = { ...error, funcStatus: "error" };
        if (i != tryCount) {
          await timer(timeOut);
        }
        timeOut += 2000;
      });
      if (response.funcStatus == "success" || i == tryCount) {
        return response;
      }
    }
  }

  isVariableCommission() {
    const subscription = this.subscriptionAvailable && this.subscriptionAvailable[0];
    if (!subscription) {
      return true;
    }
    return subscription.ruleType == VARIABLE_COMMISSION;
  }

  purchaseSubscription(subscriptionId = "", paymentId = "", transactionId = "", retryCount = 0) {
    return new Promise(async (resolve, reject) => {
      await this.captainSubscriptionService.buySubscription(subscriptionId, paymentId, transactionId, this.lang, retryCount)
        .subscribe(res => {
          resolve(res['data']);
        }, async (err) => {
          reject(err.error);
        });
    });
  }

  hideBackground(className, value) {
    const element = <HTMLElement>document.getElementsByClassName(className)[0];
    if (element) {
      element.style.display = value;
    }
  }

  getRuleConstruct(subscription) {
    if (subscription.ruleType != VARIABLE_COMMISSION) {
      return "₹0";
    }

    const rule = subscription.rule[0];

    return `${rule.value}% less`;
  }

  viewTC() {
    this.showTC = true;
  }

  cancelTC() {
    this.showTC = false;
  }

  setDefaultBannerForCab() {
    this.bannerForCab = `/assets/subscription/bannerForCab/${environment.subscriptionDefaultLanguage}.svg`;
  }
}
