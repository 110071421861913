<div *ngIf="showTemplate" class="super-saver-page">
    <div [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')'}" class="banner">
        <div class="row banner-row">
            <div class="col-4"></div>
            <img class="col-4 brandIcon" src={{brandIcon}} />
            <div class="status col-4 center inactive" *ngIf="(!isOneDayReamining && !sswIsActive && postSevenDays)">
                <img class="status-img" src="{{statusImagePath}}">
                <span class="status-text">{{status}}</span>
            </div>
            <div class="status col-4 center active" *ngIf="sswIsActive">
                <img class="status-img" src="{{statusImagePath}}">
                <span class="status-text">{{status}}</span>
            </div>
        </div>
        <img class="center" src={{superSaverIcon}} />

        <div *ngIf="sswIsActive">
            <span class="text-span-small-with-bg center">Ends
                {{sswEndDate.getDate()}}{{timeUtils.getDateSuffix(sswEndDate)}}
                {{sswEndDate.toLocaleString('default', { month: 'short' })}}
            </span>
        </div>

        <div *ngIf="isOneDayReamining">
            <span class="text-span-mid-nobg center">Save and Win BIG</span>
            <span class="center">
                <span class="text-span-large-nobg">{{reaminingTime.hour}}</span>
                <span class="text-span-mid-nobg">hr</span>
                <span class="text-span-mid-nobg">&nbsp;:&nbsp;</span>
                <span class="text-span-large-nobg">{{reaminingTime.minutes}}</span>
                <span class="text-span-mid-nobg">m</span>
                <span class="text-span-mid-nobg">&nbsp;:&nbsp;</span>
                <span class="text-span-large-nobg">{{reaminingTime.seconds}}</span>
                <span class="text-span-mid-nobg">s</span>
            </span>

        </div>

        <div *ngIf="!isOneDayReamining && !sswIsActive" class="center">
            <span class="text-span-mid-nobg center">We'll be back on </span>
            <span class="center">
                <span class="text-span-large-nobg">{{nextSswStartDate.getDate()}}</span>
                <span class="text-span-mid-nobg">{{timeUtils.getDateSuffix(nextSswStartDate)}}</span>
            </span>
            <span class="text-span-mid-nobg center">{{nextSswStartDate.toLocaleString('default', { month: 'long' })
                }}</span>
        </div>

        <a *ngIf="!sswIsActive && showNotifyButton" class="center notify-button" target="blank" (click)="notifyMe()">Notify Me</a>
    </div>

    <div *ngIf="(!isOneDayReamining && !sswIsActive && postSevenDays)">
        <p id="winner-announcement">
            Lucky draw winners of {{sswStartDate.toLocaleString('default', { month: 'long' })}}'s Rapido Super Saver
            Week
            will be announced here soon
        </p>
    </div>

    <div class="prize-section">
        <span *ngIf="!isOneDayReamining && !sswIsActive" class="notch text-span-small-with-bg-cliped center">Big
            Savings</span>

        <span class="text-span-mid-nobg black-text center">Assured cashbacks upto</span>
        <span class="text-span-large-nobg black-text center">&#x20B9;{{cashBackUpto}}</span>
        <span class="text-span-small-nobg black-text center">on every ride</span>
        <hr class="horizontal-bar">

        <span *ngIf="isOneDayReamining || sswIsActive" class="notch text-span-small-with-bg-cliped center">1 Lucky
            rider gets</span>

        <span *ngIf="!isOneDayReamining && !sswIsActive" class="notch text-span-small-with-bg-cliped center">Ride and
            win BIG</span>

        <!-- <img src="{{prize.grandPrize.url}}"/> -->

        <div class="container grandPrize">
            <div class="row">
                <div class="col-sm-12">
                    <img class="center prize-image" src="{{prize.grandPrize.url}}" />
                    <span class="center prize-name">{{prize.grandPrize.name}}</span>
                </div>
            </div>
        </div>

        <span *ngIf="isOneDayReamining || sswIsActive" class="notch text-span-small-with-bg-cliped center">More than 500
            lucky riders get</span>

        <div class="container">
            <div class="row">
                <div class="col-6" *ngFor="let item of prize.subPrize; i as index;odd as isOdd">
                    <img class="center sub-prize" src={{item.url}} />
                    <span class="center prize-name">{{item.name}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="container tnc">
        <span class="tnc-heading"> T&Cs</span>
        <hr class="hr-tnc">
        <div *ngFor="let item of tnc">
            <ul>
                <li>{{item}}</li>
            </ul>
        </div>
    </div>

    <footer>
        <div class="row">
            <span class="col-7 text-span-small-nobg black-text sticky-text">Share and increase your chances of
                winning</span>
            <a class="col-4 share-button" (click)="share()" target="blank">Share</a>
            <!-- <a *ngIf="sswIsActive" class="col-4 share-button" (click)="bookRide()" target="blank">Book a ride</a> -->
        </div>
    </footer>
</div>