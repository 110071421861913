import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CaptainSubscriptionService } from './captain-subscriptions.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
var VARIABLE_COMMISSION = "variableCommission";
var CaptainSubscriptionsComponent = /** @class */ (function () {
    function CaptainSubscriptionsComponent(sanitizer, captainSubscriptionService, route) {
        var _this = this;
        this.sanitizer = sanitizer;
        this.captainSubscriptionService = captainSubscriptionService;
        this.route = route;
        this.isCab = false;
        this.onVideoStateChange = function (event) {
            switch (event.data) {
                case window['YT'].PlayerState.PLAYING:
                    _this.captainSubscriptionService.publishEvent("subscriptionPage_training", { position: "start" });
                    break;
                case window['YT'].PlayerState.ENDED:
                    _this.captainSubscriptionService.publishEvent("subscriptionPage_training", { position: "end" });
                    break;
            }
            ;
        };
    }
    CaptainSubscriptionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.lang = this.route.snapshot.paramMap.get('lang');
        this.lang = this.lang ? this.lang : "" + environment.subscriptionDefaultLanguage;
        this.bannerForCab = "/assets/subscription/bannerForCab/" + this.lang + ".svg";
        this.subscriptionDetailsStatus = "";
        this.showBuyBox = false;
        this.buyStatus = "";
        this.buyInitiated = false;
        this.showTC = false;
        this.showLoader = false;
        this.errorPageTexts = {
            errorLoadingText: this.captainSubscriptionService.getErrorLoadingText(this.lang),
            errorPurchasingText: this.captainSubscriptionService.getErrorPurchasingText(this.lang),
            tryAgainText: this.captainSubscriptionService.getTryAgainText(this.lang),
            tryAgainButtonText: this.captainSubscriptionService.getTryAgainButtonText(this.lang),
            goBackButtonText: this.captainSubscriptionService.getGoBackButtonText(this.lang)
        };
        this.paymentStatus = this.captainSubscriptionService.getPaymentStatus();
        if (this.paymentStatus && this.paymentStatus.razor_pay_transaction_status != "") {
            if (this.paymentStatus.razor_pay_transaction_status == "success") {
                this.buy(this.paymentStatus.captain_subscription_id, this.paymentStatus.razor_pay_transaction_id, this.paymentStatus.transaction_id);
            }
            else if (this.paymentStatus.razor_pay_transaction_status == "failure") {
                this.buyInitiated = true;
                this.buyStatus = "ERROR";
                this.captainSubscriptionService.publishEvent("subscription_purchase", { status: "FAILED_RAZORPAY", failureReason: "Wallet recharge failed" });
            }
        }
        else {
            this.handleLoader(this.captainSubscriptionService.getLoadingPlanText(this.lang));
            this.captainSubscriptionService.getSubscriptionDetails(this.lang).subscribe(function (res) {
                _this.subscriptionDetailsStatus = "SUCCESS";
                _this.subscriptionDetails = res['data'];
                _this.setupYoutubeVideo(_this.subscriptionDetails.genericDetails.videoLink);
                _this.handleLoader("");
                _this.activeSubscriptionExists = _this.subscriptionDetails.activeSubscription;
                _this.subscriptionAvailable = _this.subscriptionDetails.availableSubscriptions;
                _this.nextSubsPurchased = _this.subscriptionDetails.nextSubscriptionPurchased;
                var hasboughtSubscription = _this.nextSubsPurchased && _this.activeSubscriptionExists;
                _this.canBuyNextSubscription = !hasboughtSubscription && Boolean(_this.subscriptionAvailable);
                _this.tncs = _this.getTncs();
                _this.isCab = _this.subscriptionAvailable && _this.subscriptionDetails.availableSubscriptions[0].isCabSubscription ||
                    _this.activeSubscriptionExists && _this.subscriptionDetails.activeSubscription.isCabSubscription;
                if (_this.isCab) {
                    _this.subscriptionAvailable = !_this.activeSubscriptionExists;
                    _this.canBuyNextSubscription = !_this.activeSubscriptionExists && _this.subscriptionAvailable;
                }
            }, function (error) {
                _this.subscriptionDetailsStatus = "ERROR";
                _this.handleLoader("");
            });
        }
    };
    CaptainSubscriptionsComponent.prototype.getTncs = function () {
        var subscription = this.subscriptionDetails.activeSubscription || (this.subscriptionAvailable || [])[0];
        return subscription && subscription.tncs;
    };
    CaptainSubscriptionsComponent.prototype.ngOnDestroy = function () {
        delete window['onYouTubeIframeAPIReady'];
    };
    CaptainSubscriptionsComponent.prototype.setupYoutubeVideo = function (videoLink) {
        var _this = this;
        this.setupYoutubeIframeAPI();
        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(videoLink + "?enablejsapi=1&rel=0");
        window['onYouTubeIframeAPIReady'] = function () {
            _this.player = new window['YT'].Player('player', {
                events: {
                    'onStateChange': _this.onVideoStateChange
                }
            });
        };
    };
    CaptainSubscriptionsComponent.prototype.setupYoutubeIframeAPI = function () {
        if (window['YT']) {
            return;
        }
        var tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    };
    CaptainSubscriptionsComponent.prototype.handleLoader = function (text) {
        this.showLoader = !this.showLoader;
        this.loaderText = text;
    };
    CaptainSubscriptionsComponent.prototype.buyPlan = function () {
        this.captainSubscriptionService.publishEvent("subscription_purchase_click");
        if (this.isCab) {
            this.showBuyBox = false;
            this.buy();
            return;
        }
        this.showBuyBox = true;
    };
    CaptainSubscriptionsComponent.prototype.cancelBuy = function () {
        this.showBuyBox = false;
    };
    CaptainSubscriptionsComponent.prototype.buy = function (subscriptionId, paymentId, transactionId) {
        if (subscriptionId === void 0) { subscriptionId = ""; }
        if (paymentId === void 0) { paymentId = ""; }
        if (transactionId === void 0) { transactionId = ""; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var id, res, mode, eventDetails, eventData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.handleLoader(this.captainSubscriptionService.getPurchasingPlanText(this.lang));
                        this.buyInitiated = true;
                        id = subscriptionId || this.subscriptionDetails.availableSubscriptions[0].id;
                        return [4 /*yield*/, this.retryTransaction(id, paymentId, transactionId)];
                    case 1:
                        res = _a.sent();
                        mode = paymentId === "" ? "RAPIDO" : "RAZORPAY";
                        eventDetails = this.getPurchaseEventDetails(res.data);
                        if (res.funcStatus == "success") {
                            this.buyStatus = "SUCCESS";
                            this.subscriptionBuyDetails = res;
                            this.showBuyBox = false;
                            this.hideBackground("background-info", "none");
                            this.captainSubscriptionService.publishEvent("subscription_purchase", tslib_1.__assign({ status: "SUCCESS_" + mode }, eventDetails));
                        }
                        else if (res.code == 402) {
                            this.subscriptionBuyDetails = res.data;
                            eventData = tslib_1.__assign({ status: "FAILED_" + mode }, eventDetails, { failureReason: paymentId === "" ? "Low wallet balance" : "Razorpay recharge failed" });
                            if (this.paymentStatus && this.paymentStatus.razor_pay_transaction_status == "") {
                                this.captainSubscriptionService.publishEvent("subscription_purchase", eventData);
                                this.captainSubscriptionService.rechargeWallet(this.subscriptionDetails, this.subscriptionBuyDetails);
                                this.hideBackground("background-info", "none");
                            }
                            else {
                                this.captainSubscriptionService.publishEvent("subscription_purchase", eventData);
                                this.buyStatus = "FAILED";
                            }
                        }
                        else {
                            this.captainSubscriptionService.publishEvent("subscription_purchase", tslib_1.__assign({ status: "FAILED_" + mode }, eventDetails, { failureReason: "Technical error" }));
                            this.buyStatus = "ERROR";
                            this.hideBackground("background-info", "none");
                        }
                        this.handleLoader("");
                        this.showBuyBox = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    CaptainSubscriptionsComponent.prototype.getPurchaseEventDetails = function (response) {
        if (!response || !response.balance) {
            return {};
        }
        return {
            balance: response.balance.toFixed(2),
            firstTime: response.firstTime,
        };
    };
    CaptainSubscriptionsComponent.prototype.retryTransaction = function (subscriptionId, paymentId, transactionId) {
        if (subscriptionId === void 0) { subscriptionId = ""; }
        if (paymentId === void 0) { paymentId = ""; }
        if (transactionId === void 0) { transactionId = ""; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var timeOut, tryCount, timer, response, i;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        timeOut = 2000;
                        tryCount = 0;
                        if (paymentId != "" && transactionId != "") {
                            tryCount = parseInt(environment.subscriptionPurchaseRetryCount);
                        }
                        timer = function (ms) { return new Promise(function (res) { return setTimeout(res, ms); }); };
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i <= tryCount)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.purchaseSubscription(subscriptionId, paymentId, transactionId, i).then(function (data) {
                                response = tslib_1.__assign({}, data, { funcStatus: "success" });
                            }, function (error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            response = tslib_1.__assign({}, error, { funcStatus: "error" });
                                            if (!(i != tryCount)) return [3 /*break*/, 2];
                                            return [4 /*yield*/, timer(timeOut)];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2:
                                            timeOut += 2000;
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 2:
                        _a.sent();
                        if (response.funcStatus == "success" || i == tryCount) {
                            return [2 /*return*/, response];
                        }
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CaptainSubscriptionsComponent.prototype.isVariableCommission = function () {
        var subscription = this.subscriptionAvailable && this.subscriptionAvailable[0];
        if (!subscription) {
            return true;
        }
        return subscription.ruleType == VARIABLE_COMMISSION;
    };
    CaptainSubscriptionsComponent.prototype.purchaseSubscription = function (subscriptionId, paymentId, transactionId, retryCount) {
        var _this = this;
        if (subscriptionId === void 0) { subscriptionId = ""; }
        if (paymentId === void 0) { paymentId = ""; }
        if (transactionId === void 0) { transactionId = ""; }
        if (retryCount === void 0) { retryCount = 0; }
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.captainSubscriptionService.buySubscription(subscriptionId, paymentId, transactionId, this.lang, retryCount)
                            .subscribe(function (res) {
                            resolve(res['data']);
                        }, function (err) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                reject(err.error);
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    CaptainSubscriptionsComponent.prototype.hideBackground = function (className, value) {
        var element = document.getElementsByClassName(className)[0];
        if (element) {
            element.style.display = value;
        }
    };
    CaptainSubscriptionsComponent.prototype.getRuleConstruct = function (subscription) {
        if (subscription.ruleType != VARIABLE_COMMISSION) {
            return "₹0";
        }
        var rule = subscription.rule[0];
        return rule.value + "% less";
    };
    CaptainSubscriptionsComponent.prototype.viewTC = function () {
        this.showTC = true;
    };
    CaptainSubscriptionsComponent.prototype.cancelTC = function () {
        this.showTC = false;
    };
    CaptainSubscriptionsComponent.prototype.setDefaultBannerForCab = function () {
        this.bannerForCab = "/assets/subscription/bannerForCab/" + environment.subscriptionDefaultLanguage + ".svg";
    };
    return CaptainSubscriptionsComponent;
}());
export { CaptainSubscriptionsComponent };
