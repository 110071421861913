<div class="captain-merchandise-page">
    <div class="loader" *ngIf="showLoader">
      <div class="spinner-div">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="spinner-text">{{this.loaderText}}</div>
    </div>
    <div class="wrapper" *ngIf="!showLoader">
      <!--product listing page-->
      <div *ngIf="merchandiseDetailsStatus == 'SUCCESS' && !purchaseInitiated">
        <img [src]="bannerForHelmet" class="bike-taxi-img" (error)="setDefaultBannerForMerchandise()">
        <div class="merchandise-info-wrap">
            <div class="flex-row">
              <div class="flex-col flex-grow">
                <div class="product-name">{{merchandiseDetails.name}}</div>
                <div class="product-description">{{merchandiseDetails.description}}</div>
                <div class="amount-desc">
                  <span class="original-price">
                    ₹{{merchandiseDetails.originalPrice}}
                  </span>
                  <span class="discount-price">
                    ₹{{merchandiseDetails.discountPrice}} Only
                  </span>
                </div>
              </div>
              <img src="/assets/merchandise/helmet.svg">
            </div>
            <hr />
        </div>

        <div class="terms-conditions">
          <span style="color: #2962FF; cursor: pointer;"
            (click)="viewTC()">View T&C</span>
        </div>

        <div class="sticky-section" *ngIf="!showBuyBox">
          <button class="buy-info-button" (click)="buyPlan()" *ngIf="!purchaseInitiated">
            Buy
          </button>
        </div>
      </div>
      <!--buy bottom Sheet-->
      <div class="overlay" *ngIf="showBuyBox" (click)="cancelBuy()">
        <div class="sticky-box">
          <div class="sticky-box-heading">
            Buy {{merchandiseDetails.name}}
          </div>
          <div class="sticky-box-desc">
            ₹{{merchandiseDetails.discountPrice}} will be deducted from your wallet
          </div>
          <div class="sticky-box-button" (click)="buy()">
            Buy for ₹{{merchandiseDetails.discountPrice}} Only
          </div>
          <div class="sticky-box-cancel-button" (click)="cancelBuy()">
            Cancel
          </div>
        </div>
      </div>
  
      <!--tnc bottom sheet-->
      <div class="overlay" *ngIf="showTC && tncs" (click)="cancelTC()">
        <div class="sticky-box">
          <div class="sticky-box-heading">
            Terms & Conditions
          </div>
          <div class="tc-list">
            <li *ngFor="let tc of tncs">{{ tc }}</li>
          </div>
          <div class="sticky-box-cancel-button" (click)="cancelTC()">
            Okay
          </div>
        </div>
      </div>
      <!--existing order screen-->
      <div class= "merchandise-info-wrap" *ngIf="hasExistingOrders">
        <div class="flex-row">
          <div class="flex-grow">
            <div class="current-subs-desc">
              <img src="/assets/subscription/greentickSmall.svg" class="green-tick-image">
              Successfully bought {{orderDetails.ProductDetails.Name}}
            </div>
            <div class="amount-desc">
              <span class="plan-amount">
                ₹{{orderDetails.ProductDetails.PurchasePrice}} Only
              </span>
            </div>
            <div class="success-disclaimer-text">
              Our team will reach out to you soon
            </div>
          </div>
        </div>
        <hr />
      </div>
      <!--order Confirmation page-->
      <div *ngIf="purchaseStatus == 'SUCCESS' && purchaseInitiated">
        <div class="success-page">
          <div class="success-img-wrap">
            <div class="get-big-tick-img"></div>
          </div>
          <div class="success-desc">
            Successfully bought {{productPurchaseDetails.productName}}
          </div>
          <div class="success-desc-2">
            Paid ₹{{productPurchaseDetails.purchasePrice}} Only
          </div>
          <div class="success-disclaimer">
            Our team will reach out to you soon
          </div>
        </div>
      </div>
      <!--payment error page-->
      <div *ngIf="purchaseStatus == 'ERROR' && purchaseInitiated">
        <div class="success-page">
          <div class="success-img-wrap">
            <div class="get-transaction-failed-img"></div>
          </div>
          <div class="error-desc" [innerHTML]="errorPageTexts.errorPurchasingText"></div>
          <div class="error-disclaimer">
            {{errorPageTexts.tryAgainText}}
          </div>
          <div class="sticky-box-error-page">
            <div class="sticky-box-button-error" (click)="ngOnInit()">
              {{errorPageTexts.goBackButtonText}}
            </div>
          </div>
        </div>
      </div>
      <!--product listing api error page-->
      <div *ngIf="merchandiseDetailsStatus == 'ERROR'">
        <div class="success-page">
          <div class="success-img-wrap">
            <div class="get-api-failed-img"></div>
          </div>
          <div class="error-desc" [innerHTML]="errorPageTexts.errorLoadingText"></div>
          <div class="error-disclaimer">
            {{errorPageTexts.tryAgainText}}
          </div>
          <div class="sticky-section">
            <div class="try-again" (click)="ngOnInit()">
              {{errorPageTexts.tryAgainButtonText}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  