<span *ngFor="let rule of info">
  <div class="hr-container">
    <div class="hr-content">
      {{rule.header}}
    </div>
    <div class="hr-line">
    </div>
  </div>
  <span *ngIf="rule.timeslots" class="otherData">
    {{rule.timeslots}}
  </span>
  <app-info-cards [data]="rule.data" [type]="type"></app-info-cards>
</span>