<div class = "covid-19-page" [ngClass]="{'hide-scroll': !getOverlayDisplayStatus()}">
    
    <div class = "wrapper">
        <div class = "banner-header">Your Safety Matters</div>
        <hr class = "delimiter" />

        <div>
            <div class = "banner-header">#Get Vaccine</div>
            <div class = "image-wrap">
                <div class = "get-vaccine-img"></div>
            </div>
            <div class="justify-content-center display-flex margin-bottom-40" *ngIf="deviceType === 'android'">
                <button class="book-vaccine cursor-pointer font-weight-700" (click)="redirectToBookvaccine()"> Book Vaccine </button>
            </div>
        </div>


        <div *ngIf="deviceType === 'android'">
            <div class="booking-procedure">
                <div class="color-black font-weight-700 booking-procedure-text">
                    3 step booking procedure
                        <img src="assets/covid/share.svg" class="share-icon cursor-pointer" (click)="redirectToWhatsapp()">
                </div>
                <div class="nearest-covid-vaccination">
                    Search for the nearest COVID
                    vaccination center and book a slot
                    <div class = "half-circle-img-wrap">
                        <div class="half-circle-img"></div>
                    </div>
                </div>
            </div>
    
            <div class="vaccination-steps-padding">
                <div class = "display-flex align-items-center padding-15-0" *ngFor="let steps of vaccinationBookingProcedure">
                    <div class = "info-img-wrap">
                        <div class = "background-img info-img" [ngStyle]="{'background-image': 'url(' + steps.imageUrl + ')'}"></div>
                    </div>
                    <div>
                        <div class="info-message font-size-16 font-weight-700">{{steps.header}}</div>
                        <div class="info-message font-size-14">{{steps.content}}</div>
                    </div>
                </div>
            </div>
    
            <div>
                <div class = "display-flex align-items-center vaccination-faq-padding">
                    <div class = "info-img-wrap">
                        <div class = "background-img info-img" [ngStyle]="{'background-image': 'url(' + vaccinationFAQImageURL + ')'}"></div>
                    </div>
                    <div class="display-flex">
                        <div class="info-message font-size-18 cursor-pointer" (click)="redirectToVaccinationFQQ()">Vaccination related
                            <span class="color-blue">FAQ's</span>
                        </div>
                    </div>
                </div>        
            </div>
        </div>
  
        <div class = "safety-measures-section" #learnMoreaptainGuidelines>
            <div class = "covid-section-header">Captain safety measures</div>
            <hr class = "delimiter" />
            <a  href="https://www.youtube.com/watch?v=ZXQFYYe9EEM&feature=youtu.be" target="_blank">
                <div class = "safety-measure-img-wrap">
                    <div class = "background-img safety-measure-img "></div>
                </div>
            </a>
            <div class = "safety-measures" *ngFor="let measure of captainGuidelines">
                <div class = "info-img-wrap">
                    <div class = "background-img info-img" [ngStyle]="{'background-image': 'url(' + measure.imageUrl + ')'}"></div>
                </div>
                <div class = "info-message font-size-14">{{measure.content}}</div>
            </div>
        </div>

        <div class = "learn-more" (click)="toggleLearnMoreOfCaptainMeasures(learnMoreaptainGuidelines)">Learn more</div>

        <div class = "why-bike-taxi-section p-20" #bikeTaxiImportance>
            <div class = "why-bike-taxi-wrap">
                <div class = "why-bike-taxi-content-wrap">
                    <div class = "why-bike-taxi-content">Why Bike taxi is the safest to travel?</div>
                    <div class = "more-details-wrap">
                        <div class = "more-details" (click)="toggleImportanceBikeTaxiSection(bikeTaxiImportance)">More Details</div>
                        <div (click)="toggleImportanceBikeTaxiSection(bikeTaxiImportance)"><img src = "/assets/covid/forward.svg" /></div>
                    </div>
                </div>
                <div class = "why-bike-taxi-img-wrap">
                    <div class = "why-bike-taxi-img"></div>
                </div>
            </div>
        </div>

        <div class = "safety-measures-section p-20" #customerRecommendation>
            <div class = "covid-section-header">Recommendations to customers</div>
            <hr class = "delimiter" />
            <div class = "safety-measures" *ngFor="let measure of customerGuidelines">
                <div class = "info-img-wrap">
                    <div class = "background-img info-img" [ngStyle]="{'background-image': 'url(' + measure.imageUrl + ')'}"></div>
                </div>
                <div class = "info-message font-size-14">{{measure.content}}</div>
            </div>
        </div>

        <div class = "learn-more" (click)="toggleLearnMoreOfCustomerMeasures(customerRecommendation)">Learn more</div>

        <div class = "go-cashless-banner-section p-20">
            <div class = "cashless-banner-wrap">
                <div class = "cashless-banner-content-wrap">
                    <div class = "cashless-banner-header">#Go Cashless</div>
                    <div class = "cashless-banner-content">Use cashless payments to reduce risk of covid spread</div>
                </div>
                <div class = "cashless-banner-img-wrap">
                    <div class = "cashless-banner-img"></div>
                </div>
            </div>
        </div>

        <div class = "customer-testimonials-section p-20">
            <div class = "covid-section-header">What customers are saying</div>
            <hr class = "delimiter" />
            <ngx-slick-carousel 
                class="carousel" 
                #slickModal="slick-carousel" 
                [config]="slideConfig">
                <div ngxSlickItem *ngFor="let testimonial of customerFeedback" class="slide">
                    <div class = "customer-feedback-wrap">
                        <div class = "customer-feedback">{{testimonial.feedback}}</div>
                        <div class = "feedback-content-wrap">
                            <div class = "testimonial-img-wrap">
                                <div class = "background-img testimonial-img"  [ngStyle]="{'background-image': 'url(' + testimonial.imageUrl + ')'}">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>

        <div class = "faq-sections p-20">
            <div class = "covid-section-header">FAQ's</div>
            <hr class = "delimiter" />
            <div *ngFor="let faq of faqs;let i = index">
                <div class = "faq-list-item">
                    <div class = "expand-collapse-wrap" (click)="toggle(i)">
                        <div class="expand-collapse-title">{{faq.header}}</div>
                        <div class="expand-collapse">
                            <img 
                                src = "/assets/covid/down-arrow.svg" 
                                class = "down-arrow-img"
                                [ngClass]="{'collapse-img-wrap': hideRuleContent[i]}"
                            />
                        </div>
                    </div>
                        <div class="expand-collapse-content" *ngIf="hideRuleContent[i]">
                        {{faq.content}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Extra Guidelines for the Captains   -->
<div class = "captain-measures-guideline-section" [hidden] = "!learnMoreCaptainMeasuresSection">
    <div class = "covid-section-header p-20"> Extra guidelines our captains take to ensure your safety</div>
    <div class = "safety-measures learn-more-guidelines" *ngFor="let guideline of captainMeasuresGuidelines">
        <div class = "info-img-wrap">
            <div class = "background-img info-img" [ngStyle]="{'background-image': 'url(' + guideline.imageUrl + ')'}"></div>
        </div>
        <div class = "safety-measure-content-wrap">
            <div class = "safety-measure-content">{{guideline.content}}</div>
        </div>
    </div>
    <div class = "sound-safe-btn" (click)="toggleLearnMoreOfCaptainMeasures(undefined)">Okay, got it</div>
</div>

<!-- Extra Guidelines for the Customers   -->
<div class = "customer-measures-guideline-section" [hidden] = "!learnMoreCustomerMeasuresSection">
    <div class = "covid-section-header p-20">Extra guidelines recommended to ensure your extra safety</div>
    <div class = "safety-measures learn-more-guidelines" *ngFor="let guideline of customerMeasuresGuidelines">
        <div class = "info-img-wrap">
            <div class = "background-img info-img" [ngStyle]="{'background-image': 'url(' + guideline.imageUrl + ')'}"></div>
        </div>
        <div class = "safety-measure-content-wrap">
            <div class = "safety-measure-content">{{guideline.content}}</div>
        </div>
    </div>
    <div class = "sound-safe-btn" (click)="toggleLearnMoreOfCustomerMeasures(undefined)">Okay, got it</div>
</div>

<!-- Why Bike Taxi is importance modal  -->
<div class = "bike-taxi-importance-section" [hidden] = "!showBikeTaxiImportanceSection">
    <div class = "covid-section-header bike-taxi-imprtnce-hdr"> Why bike taxi is the safest travel during Covid19 ?</div>
    <div class = "safety-measures" *ngFor="let guideline of safetyGuidelines">
        <div class = "info-img-wrap">
            <div class = "background-img tick-image"></div>
        </div>
        <div class = "safety-measure-content-wrap">
            <div class = "safety-measure-header">{{guideline.header}}</div>
            <div class = "safety-measure-content">{{guideline.content}}</div>
        </div>
    </div>
    <div class = "sound-safe-btn" (click)="toggleImportanceBikeTaxiSection(undefined)">Okay, sounds safe</div>
</div>

<div class = "overlay" [hidden] = "getOverlayDisplayStatus()"></div>
