import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
var ViewIncentiveService = /** @class */ (function () {
    function ViewIncentiveService(http) {
        this.http = http;
        this.server = "" + environment.server;
        this.getIncentiveDetails = this.server + '/api/getIncentiveDetails';
    }
    ViewIncentiveService.prototype.getDetails = function (id) {
        return this.http.get(this.getIncentiveDetails + '/' + id).pipe(catchError(this.handleError));
    };
    ViewIncentiveService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        }
        else {
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        return throwError(error);
    };
    ViewIncentiveService.ngInjectableDef = i0.defineInjectable({ factory: function ViewIncentiveService_Factory() { return new ViewIncentiveService(i0.inject(i1.MyHttpClient)); }, token: ViewIncentiveService, providedIn: "root" });
    return ViewIncentiveService;
}());
export { ViewIncentiveService };
