<div class="mainContainer">
    <div class="topHeader">
        <img src="https://i.ibb.co/1ngjB1g/Group-2817.png" class="topHeaderPic">
    </div>	
    <div id="innercontainer">
        <div class="" id="toptext">
            Rules to follow to be a Rapido captain and receive more orders
        </div>

        <div class="slot">
            <div class="pic">
                <img src="https://i.ibb.co/1MMwC61/Group-2814.png" class="imagePic"/>
            </div>
            <div class="heading">
                Behaviour and Professionalism
            </div>
            <div>
                <div class="items">- Avoid using bad/unprofessional language with customers.</div>
                <div class="items">- Never demand extra money and return the change to the customers.</div>
                <div class="items">- Don’t force the customer to cancel the ride if they choose to pay online.</div>
                <div class="items">- Don’t make unnecessary contacts with the customer before and after the trip</div>
            </div>

        </div>

        <div class="slot">
            <div class="pic">
                <img src="https://i.ibb.co/HFzC2N0/Group-2815-1.png" class="imagePic"/>
            </div>
            <div class="heading">
                Driving and Navigation
            </div>
            <div>
                <div class="items">- Follow speed limit and don’t engage in fast/rash riding </div>
                <div class="items">- Arrive at the correct pickup point and drop at right location.</div>
                <div class="items">- Follow the route shown in the app and always keep your GPS on.</div>
            </div>

        </div>
        <div class="slot">
            <div class="pic">
            <img src="https://i.ibb.co/9Yq5XhH/Group-2816-1.png" class="imagePic"/>
            </div>
            <div class="heading">
                Cleanliness and Hygiene
            </div>
            <div>
                <div class="items">- Maintain cleanliness and groom yourself.</div>
                <div class="items">- Don’t force the customer to cancel the ride if they choose to pay online.</div>
                <div class="items">- Don’t make unnecessary contacts with the customer before and after the trip</div>
            </div>

        </div>
        <div class="" id="toptext">
            <b>Note: You will be deactivated if you fail to adhere to the above guidelines.</b>
        </div>

        <div style="margin-bottom: 50px;">
            <img style="max-width: 60%;" src="https://i.ibb.co/rtjD18g/Ride-Safe-Captains.png">
        </div>
    </div>
</div>

<!-- <div style="position: absolute;top: 20px;left: 30px; width: 50px;">
    <img src="https://i.ibb.co/FKJDy7D/Vector-1.png" width="20" onclick="window.history.back();" />
</div> -->