import * as tslib_1 from "tslib";
import { MyHttpClient } from '../../app/http-client.service';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
var CaptainSubscriptionService = /** @class */ (function () {
    function CaptainSubscriptionService(http) {
        this.http = http;
        this.server = environment.captainServer;
        this.publishEventApi = this.server + '/subscriptions/v0/captain/events';
        this.buySubscriptionApi = this.server + '/subscriptions/v0/captain/';
        this.getSubscriptionDetailsApi = this.server + '/subscriptions/v0/captain';
    }
    CaptainSubscriptionService.prototype.getToken = function () {
        return MyFunction && MyFunction.getAuthToken();
    };
    CaptainSubscriptionService.prototype.getSubscriptionDetails = function (lang) {
        return this.http.executeGetRequest(this.getSubscriptionDetailsApi, this.getToken(), lang);
    };
    CaptainSubscriptionService.prototype.buySubscription = function (subscriptionId, paymentId, transactionId, lang, retryCount) {
        var url = this.buySubscriptionApi + subscriptionId;
        var body = {};
        if (paymentId != "" && transactionId != "") {
            body = {
                paymentId: paymentId,
                transactionId: transactionId
            };
        }
        body["retryCount"] = retryCount;
        return this.http.executePostRequest(url, body, {}, {}, this.getToken(), lang);
    };
    CaptainSubscriptionService.prototype.getPaymentStatus = function () {
        if (MyFunction && MyFunction.getSubscriptionPaymentStatus) {
            var data = MyFunction && MyFunction.getSubscriptionPaymentStatus && MyFunction.getSubscriptionPaymentStatus();
            try {
                return JSON.parse(data);
            }
            catch (_a) {
                return null;
            }
        }
        return null;
    };
    CaptainSubscriptionService.prototype.rechargeWallet = function (getDetails, buyDetails) {
        var amount = buyDetails.amount.toString() || getDetails.availableSubscriptions[0].amount.toString();
        var title = buyDetails.title;
        var transactionId = buyDetails.transactionId;
        var subscriptionId = getDetails.availableSubscriptions[0].id;
        var page = window.location.href;
        MyFunction && MyFunction.openRechargeWalletForSubscription && MyFunction.openRechargeWalletForSubscription(amount, title, transactionId, subscriptionId, page);
    };
    CaptainSubscriptionService.prototype.publishEvent = function (eventName, eventData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.http.executePostRequest(this.publishEventApi, {
                    eventName: eventName,
                    eventData: eventData
                }, {}, {}, this.getToken(), "").subscribe();
                return [2 /*return*/];
            });
        });
    };
    CaptainSubscriptionService.prototype.getLoadingPlanText = function (lang) {
        switch (lang) {
            case "en": return "Loading your Plan ...";
            case "hi": return "प्लान लोड हो रहा है ...";
            case "te": return "మీ ప్లాన్ లోడ్ అవుతోంది ...";
            case "kn": return "ನಿಮ್ಮ ಪ್ಲಾನ್ ಲೋಡ್ ಆಗುತ್ತಿದೆ ...";
            case "ta": return "உங்கள் திட்டத்தை ஏற்றுகிறது ...";
            case "bn": return "প্ল্যান লোড হচ্ছে ...";
        }
    };
    CaptainSubscriptionService.prototype.getPurchasingPlanText = function (lang) {
        switch (lang) {
            case "en": return "Purchasing Subscription Plan ...";
            case "hi": return "प्लान खरीदा जा रहा है ...";
            case "te": return "సబ్‌స్క్రిప్‌షన్ ప్లాన్ కొనుగోలు చెయ్యబడుతోంది ...";
            case "kn": return "ಸಬ್‌ಸ್ಕ್ರಿಪ್ಶನ್ ಪ್ಲಾನನ್ನು ಖರೀದಿಸಲಾಗುತ್ತಿದೆ...";
            case "ta": return "சந்தா திட்டம் வாங்குதல் ...";
            case "bn": return "সাবস্ক্রিপশন প্ল্যান কেনা হচ্ছে ...";
        }
    };
    CaptainSubscriptionService.prototype.getErrorLoadingText = function (lang) {
        switch (lang) {
            case "en": return "Sorry, we were not able to load the <br> page right now";
            case "hi": return "हमे खेद है , हम अभी पेज <br> खोलने करने में असमर्थ है    ";
            case "te": return "క్షమించండి! ఈ పేజీ ఇప్పుడు <br> లోడ్ చెయ్యలేకపోతున్నాము";
            case "kn": return "ಕ್ಷಮಿಸಿ, ಇದೀಗ <br> ಪುಟವನ್ನು ಲೋಡ್ ಮಾಡಲು ಸಾಧ್ಯವಾಗಲಿಲ್ಲ";
            case "ta": return "மன்னிக்கவும், இப்போது <br> பக்கத்தை ஏற்ற முடியவில்லை";
            case "bn": return "দুঃখিত, <br> পেজটা এই মুহূর্তে লোড হচ্ছে না";
        }
    };
    CaptainSubscriptionService.prototype.getTryAgainText = function (lang) {
        switch (lang) {
            case "en": return "Please try again...";
            case "hi": return "कृपया वापस प्रयास करे...";
            case "te": return "దయచేసి తరువాత ప్రయత్నించండి...";
            case "kn": return "ದಯವಿಟ್ಟು ಪುನಃ ಪ್ರಯತ್ನಿಸಿ...";
            case "ta": return "தயவு செய்து மீண்டும் முயற்சிக்கவும்...";
            case "bn": return "আবার চেষ্টা করুন...";
        }
    };
    CaptainSubscriptionService.prototype.getTryAgainButtonText = function (lang) {
        switch (lang) {
            case "en": return "Try Again";
            case "hi": return "वापस प्रयास करे";
            case "te": return "మళ్ళీ ప్రయత్నించండి";
            case "kn": return "ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ";
            case "ta": return "மீண்டும் முயற்சி செய்";
            case "bn": return "আবার চেষ্টা করুন";
        }
    };
    CaptainSubscriptionService.prototype.getGoBackButtonText = function (lang) {
        switch (lang) {
            case "en": return "Go Back";
            case "hi": return "पीछे जाए";
            case "te": return "వెనక్కి వెళ్లండి";
            case "kn": return "ಹಿಂತಿರುಗಿ";
            case "ta": return "திரும்பி செல்";
            case "bn": return "ফিরে যান";
        }
    };
    CaptainSubscriptionService.prototype.getErrorPurchasingText = function (lang) {
        switch (lang) {
            case "en": return "Sorry, we were not able to complete <br> your purchase";
            case "hi": return "हमे खेद है आपकी खरीद <br> सफल नहीं रही ";
            case "te": return "క్షమించండి! మీ కొనుగోలు <br> పూర్తి చెయ్యలేకపోయాము";
            case "kn": return "ಕ್ಷಮಿಸಿ, ನಿಮ್ಮ ಖರೀದಿಯನ್ನು <br> ಪೂರ್ಣಗೊಳಿಸಲು ಸಾಧ್ಯವಾಗಲಿಲ್ಲ";
            case "ta": return "மன்னிக்கவும், நாங்கள் உங்கள் கொள்முதல் <br> முடிக்க முடியவில்லை";
            case "bn": return "দুঃখিত, আমরা আপনার কেনা <br> সম্পূর্ণ করতে পারিনি";
        }
    };
    CaptainSubscriptionService.ngInjectableDef = i0.defineInjectable({ factory: function CaptainSubscriptionService_Factory() { return new CaptainSubscriptionService(i0.inject(i1.MyHttpClient)); }, token: CaptainSubscriptionService, providedIn: "root" });
    return CaptainSubscriptionService;
}());
export { CaptainSubscriptionService };
