import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-covid19',
  templateUrl: './covid19.component.html',
  styleUrls: ['./covid19.component.css']
})
export class Covid19Component implements OnInit {
    captainGuidelines: { imageUrl: string; content: string; }[];
    customerGuidelines: { imageUrl: string; content: string; }[];
    vaccinationBookingProcedure: { imageUrl: string; header: string; content: string;}[];
    slides: number[];
    // tslint:disable-next-line: max-line-length
    slideConfig: { slidesToShow: number; slidesToScroll: number; nextArrow: string; prevArrow: string; dots: boolean; infinite: boolean; autoplay: boolean; speed: number; };
    safetyGuidelines: { header: string; content: string; }[];
    showBikeTaxiImportanceSection: boolean;
    faqs: { header: string; content: string; }[];

    public hideRuleContent: boolean[] = [];
    customerFeedback: { feedback: string; imageUrl: string; }[];
    learnMoreCaptainMeasuresSection: boolean;
    learnMoreCustomerMeasuresSection: any;
    captainMeasuresGuidelines: { imageUrl: string; content: string; }[];
    customerMeasuresGuidelines: { imageUrl: string; content: string; }[];
    helpGuidelines: { imageUrl: string; content: string; }[];

    vaccinationFAQImageURL = '/assets/covid/vaccination-faq.svg'
    
    deviceType = 'android';

    constructor(
        private spinnerService: Ng4LoadingSpinnerService,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {

        this.deviceType = this.route.snapshot.queryParamMap.get('device') || 'android';
        this.slideConfig = {
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: '<div class="nav-btn next-slide"></div>',
            prevArrow: '<div class="nav-btn prev-slide"></div>',
            dots: true,
            infinite: true,
            autoplay: true,
            speed: 500
        };

        this.captainGuidelines = [
            {
                imageUrl: '/assets/covid/arogya-sethu.svg',
                content: 'Downloading Aarogya Setu app and taking the safety assessment'
            }, {
                imageUrl: '/assets/covid/sanitize.svg',
                content: 'Captains sanitize their hands before and after every ride'
            }, {
                imageUrl: '/assets/covid/face-mask-with-glouse.svg',
                content: 'Captains wear masks and gloves at all times'
            }
        ];

        this.vaccinationBookingProcedure = [
            {
                imageUrl: '/assets/covid/register-yourself.svg',
                content: 'Register using your mobile no. or aadhar no. or any other identity on COWIN website',
                header: 'REGISTER YOURSELF'
            }, 
            {
                imageUrl: '/assets/covid/vaccination-location.svg',
                content: 'Select your nearby Vaccine Center for vaccination',
                header: 'CHOOSE VACCINE LOCATION'

            }, 
            {
                imageUrl: '/assets/covid/confirm-slot.svg',
                content: 'Book your slot to get Vaccine',
                header: 'CONFIRM YOUR SLOT'
            }
        ];

        this.customerGuidelines = [
            {
                imageUrl: '/assets/covid/helmet.svg',
                content: 'We suggest you carry helmet for safety purpose'
            }, {
                imageUrl: '/assets/covid/sanitize.svg',
                content: 'Sanitize your hands before and after a ride'
            }, {
                imageUrl: '/assets/covid/face-mask.svg',
                content: 'Be sure to wear a mask that covers your nose and mouth'
            }
        ];

        this.customerFeedback = [
            {
                feedback: '“ The Captain followed all protocols and made me feel safe ”',
                imageUrl: '/assets/covid/customer1.jpg'
            },
            {
                feedback: '“ The Captain gave me sanitizer and requested me to wear the mask ”',
                imageUrl: '/assets/covid/customer2.jpg'
            },
            {
                feedback: '“ I was happy to see the bikes were sanitized and clean ”',
                imageUrl: '/assets/covid/customer3.jpg'
            }
        ];

        this.safetyGuidelines = [
            {
                header: 'Extra safe commute during COVID-19',
                content: 'Lesser touch points compared to other public modes of transport like cabs, buses, and trains'
            },
            {
                header: 'Lesser chances of getting infected on a bike',
                content: 'Due to open-air conditions less chances of air transmission of the virus'
            },
            {
                header: 'It is a 100% COVID-19 safe and hygienic',
                content: 'Driver-partners wear protective masks and helmets during the ride and bikes are sanitised regularly'
            }
        ];

        this.faqs = [
            {
                header: 'How do I stay safe?',
                content: 'Avoid crowded places & use alcohol-based hand sanitizer before and after every ride.'
            },
            {
                header: 'How do I restrain from getting infected?',
                content: 'Wear a mask at all times for the benefit of yourself & others around you. We encourage BYOH (Bring Your Own Helmet) for a safer ride.'
            },
            {
                header: 'Is it safe traveling with Rapido?',
                content: 'Yes, as the driver-captains’ must wear protective gear like helmet, mask, and also sanitize their vehicle regularly.'
            },
            {
                header: 'Why should I choose Rapido?',
                content: 'Rapido is one of the safest forms of traveling since it’s in the open and has lesser surface areas to contract COVID-19'
            },
            {
                header: 'How do I pay for my trip?',
                content: 'It\'s always best to avoid any interaction due to cash payment, try to go cashless with Rapido Wallet.'
            }
        ];

        this.captainMeasuresGuidelines = [
            {
                imageUrl: '/assets/covid/captain-safety-unwell.svg',
                content: 'Captains don’t accept any rides if they feel unwell'
            }, {
                imageUrl: '/assets/covid/captain-safety-wallet.svg',
                content: 'Captains use Rapido Wallet for contactless payments'
            }, {
                imageUrl: '/assets/covid/captain-safety-bike.svg',
                content: 'Captains wash their vehicles everyday, and keep it well sanitized'
            }
        ];

        this.customerMeasuresGuidelines = [
            {
                imageUrl: '/assets/covid/customer-safety-wallet.svg',
                content: 'Use Rapido Wallet for contactless payments'
            }, {
                imageUrl: '/assets/covid/customer-safety-temperature.svg',
                content: 'Do check your body temperature before stepping out for a ride'
            }, {
                imageUrl: '/assets/covid/customer-safety-unwell.svg',
                content: 'Do not travel outdoors if you have any symptoms of cold, cough, frequent sneezes, and difficulty in breathing'
            }
        ];

        this.helpGuidelines = [
            {
                imageUrl: '/assets/covid/doctor.svg',
                content: 'Get unlimited support from health-care experts 24/7'
            }, {
                imageUrl: '/assets/covid/telephone-video-call.svg',
                content: 'Telephone/video consultation for all your COVID concerns'
            }, {
                imageUrl: '/assets/covid/speak-to-doctor.svg',
                content: 'Speak to doctors who are fluent in 7 languages for FREE'
            }
        ];
    }

    toggleImportanceBikeTaxiSection = (el: HTMLElement) => {
        if (el) {
            el.scrollIntoView();
            document.body.className = 'no-scroll';
            this.showBikeTaxiImportanceSection = !this.showBikeTaxiImportanceSection;
        } else {
            document.body.className = '';
            this.showBikeTaxiImportanceSection = !this.showBikeTaxiImportanceSection;
        }
    }

    toggleLearnMoreOfCaptainMeasures = (el: HTMLElement) => {
        if (el) {
            el.scrollIntoView();
            document.body.className = 'no-scroll';
            this.learnMoreCaptainMeasuresSection = !this.learnMoreCaptainMeasuresSection;
        } else {
            document.body.className = '';
            this.learnMoreCaptainMeasuresSection = !this.learnMoreCaptainMeasuresSection;
        }
    }

    toggleLearnMoreOfCustomerMeasures = (el: HTMLElement) => {
        if (el) {
            el.scrollIntoView();
            document.body.className = 'no-scroll';
            this.learnMoreCustomerMeasuresSection = !this.learnMoreCustomerMeasuresSection;
        } else {
            document.body.className = '';
            this.learnMoreCustomerMeasuresSection = !this.learnMoreCustomerMeasuresSection;
        }
    }

    toggle = (index: string | number) => {
        // toggle based on index
        this.hideRuleContent[index] = !this.hideRuleContent[index];
    }

    getOverlayDisplayStatus = () => {
        return !(this.learnMoreCaptainMeasuresSection || this.showBikeTaxiImportanceSection || this.learnMoreCustomerMeasuresSection);
    }

    redirectToBookvaccine = () => {

        this.spinnerService.show();
        setTimeout(() => {
            this.spinnerService.hide();
            window.location.href = `${environment.bookVaccinationUrl}`
        }, 2000)
    }

    redirectToVaccinationFQQ = () => {
        this.spinnerService.show();
        setTimeout(() => {
            this.spinnerService.hide();
            window.location.href = `${environment.vaccinationFAQUrl}`
        }, 2000)
    }

    redirectToWhatsapp = () => {
        let message = encodeURIComponent(environment.covidShareMessage);
        window.open(
            `https://wa.me/?text=${message}`,
            '_blank' 
        );
    }
}
