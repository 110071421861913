import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-captain-whatsapp-registration',
  templateUrl: './captain-whatsapp-registration.component.html',
  styleUrls: ['./captain-whatsapp-registration.component.css']
})
export class CaptainWhatsappRegistrationComponent implements OnInit {

  public optedIn = true;
  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {

    const scriptGoogleAnalytics = this.renderer2.createElement('script');
    scriptGoogleAnalytics.src = `https://www.googletagmanager.com/gtag/js?id=UA-56717297-6`;
    scriptGoogleAnalytics.text = `<!-- Global site tag (gtag.js) - Google Analytics -->
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-56717297-6');`;

    const script = this.renderer2.createElement('script');
    script.text = `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '857628661017736');
    fbq('track', 'PageView');`;

    const noscript = this.renderer2.createElement('noscript');
    noscript.text = `<img height=“1” width=“1" style=“display:none”
    src=“https://www.facebook.com/tr?id=857628661017736&ev=PageView&noscript=1"/>`;

    this.renderer2.appendChild(this.document.body, scriptGoogleAnalytics);
    this.renderer2.appendChild(this.document.body, script);
    this.renderer2.appendChild(this.document.body, noscript);
  }
  onWhatsappClick() {
    if (this.optedIn) {
      window.open(
        `https://wa.me/917406885225?text=I%27m%20interested%20in%20becoming%20a%20Rapido%20Captain`
        );
    }
  }

}
