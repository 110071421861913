<div class="captain-subscription-page">
  <div class="loader" *ngIf="showLoader">
    <div class="spinner-div">
      <div class="spinner-border" role="status"></div>
    </div>
    <div class="spinner-text">{{this.loaderText}}</div>
  </div>
  <div class="wrapper" *ngIf="!showLoader">
    <div *ngIf="subscriptionDetailsStatus == 'SUCCESS' && !buyInitiated">
      <img src="/assets/subscription/bannerPlatformCharge.svg" class="bike-taxi-img"
        *ngIf="!activeSubscriptionExists && !isVariableCommission() && !isCab">
      <img src="/assets/subscription/payLessBanner.svg" class="bike-taxi-img"
        *ngIf="!activeSubscriptionExists && isVariableCommission() && !isCab">
        <img [src]="bannerForCab" class="bike-taxi-img" (error)="setDefaultBannerForCab()"
          *ngIf="!activeSubscriptionExists && isCab">
      <div class="subscription-info-wrapper" *ngIf="subscriptionDetailsStatus == 'SUCCESS' && !buyInitiated">
        <div *ngIf="activeSubscriptionExists">
          <div class="flex-row">
            <div class="flex-grow">
              <div class="current-subs-desc">
                <img src="/assets/subscription/greentickSmall.svg" class="green-tick-image">
                {{subscriptionDetails.activeSubscription.heading.text}}
              </div>
              <div class="amount-desc" *ngIf="isCab">
                <span class="plan-amount">
                  ₹{{subscriptionDetails.activeSubscription.amount}}
                </span>
                <span>
                  {{subscriptionDetails.genericDetails.planKeyword}}
                </span>
              </div>
              <div class="current-subs-date">
                <span *ngIf="!isCab" class="plan-description-date">{{subscriptionDetails.activeSubscription.title.text}} </span>
                <span
                  class="plan-description-services">{{subscriptionDetails.activeSubscription.description.text}}</span>
              </div>
              <div class="commission-desc" *ngIf="isCab">
                <span>{{subscriptionDetails.genericDetails.commissionText}}:</span>
                <span class="commission-rule">{{getRuleConstruct(subscriptionDetails.activeSubscription)}}</span>
              </div>
            </div>
          </div>
          <hr />
        </div>

        <div class="next-week-purchased" *ngIf="nextSubsPurchased && !isCab">
          <div>{{subscriptionDetails.genericDetails.nextWeekPlanPurchased}}</div>
        </div>

        <div class="subscription-info" *ngIf="!nextSubsPurchased">
          <div *ngIf="subscriptionAvailable">
            <div class="flex-col flex-grow">
              <div class="plan-description">{{subscriptionDetails.availableSubscriptions[0].planText}}</div>
              <div>
                <span class="plan-description-date" *ngIf="!isCab">
                  {{subscriptionDetails.availableSubscriptions[0].date.text}}
                </span>
                <span class="plan-description-services" *ngIf="!isCab">
                  {{subscriptionDetails.availableSubscriptions[0].description.text}}
                </span>
              </div>

              <div class="amount-desc">
                <span class="plan-amount">
                  {{subscriptionDetails.availableSubscriptions[0].title.text}}
                  <span *ngIf="isCab">
                    {{subscriptionDetails.genericDetails.planKeyword}}
                  </span>
                  <span *ngIf="subscriptionDetails.availableSubscriptions[0].exclusiveOfTax">
                    + {{subscriptionDetails.availableSubscriptions[0].taxPercent}}% GST
                  </span>
                </span>
                <span class="strikedOut" *ngIf="subscriptionDetails.availableSubscriptions[0].strikedOutTitle && !isCab">
                  {{subscriptionDetails.availableSubscriptions[0].strikedOutTitle.text}}
                </span>

                <span *ngIf="subscriptionDetails.availableSubscriptions[0].strikedOutTitle && !isCab" class="percentage-style">
                  {{subscriptionDetails.availableSubscriptions[0].discountedPercentage.text}}
                </span>
              </div>
              <div class="commission-desc">
                <span>{{subscriptionDetails.genericDetails.commissionText}}:</span>
                <span class="commission-rule">{{getRuleConstruct(subscriptionDetails.availableSubscriptions[0])}}</span>
              </div>
            </div>
          </div>
          <div class="no-plans-available" *ngIf="!subscriptionAvailable && !isCab">
            <div class="title">{{subscriptionDetails.genericDetails.noPlansAvailable}}</div>
            <div class="sub-title">{{subscriptionDetails.genericDetails.noPlansAvailableNotifyMessage}}</div>
          </div>
        </div>

        <div class="savings-image-wrap" *ngIf="subscriptionDetails.savingsData != null && !isCab">
          <div class="flex-row">
            <div class="flex-col flex-grow">
              <div style="font-weight: 500; font-size:20px">{{subscriptionDetails.savingsData.amountText}}</div>
              <div>{{subscriptionDetails.savingsData.subText}}</div>
            </div>
            <img src="/assets/subscription/savings.svg">
          </div>
        </div>

        <div class="video-section" *ngIf="!isCab">
          <hr />
          <iframe id="player" width="100%" height="100%" allow="fullscreen;" [src]="safeSrc">
          </iframe>
          <hr />
        </div>
        <div class="terms-conditions" *ngIf="tncs">
          <span style="color: #2962FF; cursor: pointer;"
            (click)="viewTC()">{{subscriptionDetails.genericDetails.termsConditionLinkText}}</span>
        </div>
      </div>
      <div class="sticky-section" *ngIf="!showBuyBox && canBuyNextSubscription">
        <button class="buy-info-button" (click)="buyPlan()" *ngIf="!buyInitiated">
          {{subscriptionDetails.availableSubscriptions[0].purchaseButtonText}}
        </button>
      </div>
    </div>

    <div class="overlay" *ngIf="showBuyBox" (click)="cancelBuy()">
      <div class="sticky-box">
        <div class="sticky-box-heading">
          {{subscriptionDetails.genericDetails.subscriptionPlanHeading}}
        </div>
        <div class="sticky-box-desc" [innerHTML]="subscriptionDetails.availableSubscriptions[0].deductionText">
        </div>
        <div class="sticky-box-button" (click)="buy()">
          {{subscriptionDetails.availableSubscriptions[0].purchaseButtonText}}
        </div>
        <div class="sticky-box-cancel-button" (click)="cancelBuy()">
          {{subscriptionDetails.genericDetails.cancelText}}
        </div>
      </div>
    </div>


    <div class="overlay" *ngIf="showTC && tncs" (click)="cancelTC()">
      <div class="sticky-box">
        <div class="sticky-box-heading">
          {{subscriptionDetails.genericDetails.termsConditionHeading}}
        </div>
        <div class="tc-list">
          <li *ngFor="let tc of tncs">{{ tc }}</li>
        </div>
        <div class="sticky-box-cancel-button" (click)="cancelTC()">
          {{subscriptionDetails.genericDetails.closeText}}
        </div>
      </div>
    </div>

    <div class="overlay" *ngIf="buyStatus == 'FAILED' && buyInitiated" (click)="ngOnInit()">
      <div class="failure-box">
        <div class="low-balance-img-wrap">
          <div class="get-wallet-img"></div>
        </div>
        <div class="failure-desc" [innerHTML]="this.subscriptionBuyDetails.message">
        </div>
        <div class="failure-balance">
          Current balance: {{this.subscriptionBuyDetails.balanceString}}
        </div>
        <div class="cancel-failure-button" (click)="ngOnInit()">
          {{subscriptionDetails.genericDetails.closeText}}
        </div>
      </div>
    </div>

    <div *ngIf="buyStatus == 'SUCCESS' && buyInitiated">
      <div class="success-page">
        <div class="success-img-wrap">
          <div class="get-big-tick-img"></div>
        </div>
        <div class="success-desc">
          {{this.subscriptionBuyDetails.message}}
        </div>
        <div class="success-date">
          {{this.subscriptionBuyDetails.description}}
        </div>
        <div class="success-desc-2">
          {{this.subscriptionBuyDetails.description2}}
        </div>
        <div class="success-disclaimer">
          {{this.subscriptionBuyDetails.additionalInfo}}
        </div>
        <div class="sticky-section">
          <div class="cancel-success-button" (click)="ngOnInit()">
            {{this.subscriptionBuyDetails.closeText}}
          </div>
        </div>

      </div>
    </div>

    <div *ngIf="buyStatus == 'ERROR' && buyInitiated">
      <div class="success-page">
        <div class="success-img-wrap">
          <div class="get-transaction-failed-img"></div>
        </div>
        <div class="error-desc" [innerHTML]="errorPageTexts.errorPurchasingText"></div>
        <div class="error-disclaimer">
          {{errorPageTexts.tryAgainText}}
        </div>
        <div class="sticky-box-error-page">
          <div class="sticky-box-button" (click)="ngOnInit()">
            {{errorPageTexts.goBackButtonText}}
          </div>
          <div class="sticky-box-cancel-button" (click)="buy()"
            *ngIf="paymentStatus && paymentStatus.razor_pay_transaction_status == ''">
            {{errorPageTexts.tryAgainButtonText}}
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="subscriptionDetailsStatus == 'ERROR'">
      <div class="success-page">
        <div class="success-img-wrap">
          <div class="get-api-failed-img"></div>
        </div>
        <div class="error-desc" [innerHTML]="errorPageTexts.errorLoadingText"></div>
        <div class="error-disclaimer">
          {{errorPageTexts.tryAgainText}}
        </div>
        <div class="sticky-section">
          <div class="try-again" (click)="ngOnInit()">
            {{errorPageTexts.tryAgainButtonText}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
