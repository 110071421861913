/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./captainAppDownloadDemo-webview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./captainAppDownloadDemo-webview.component";
var styles_CaptainAppDownloadDemoWebview = [i0.styles];
var RenderType_CaptainAppDownloadDemoWebview = i1.ɵcrt({ encapsulation: 0, styles: styles_CaptainAppDownloadDemoWebview, data: {} });
export { RenderType_CaptainAppDownloadDemoWebview as RenderType_CaptainAppDownloadDemoWebview };
export function View_CaptainAppDownloadDemoWebview_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"], ["allowfullscreen", ""], ["class", "youtube-video"], ["frameborder", "0"], ["referrerpolicy", "strict-origin-when-cross-origin"], ["src", "https://www.youtube.com/embed/tWFLu6uUgr8"], ["title", "YouTube video player"]], null, null, null, null, null))], null, null); }
export function View_CaptainAppDownloadDemoWebview_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "captainAppDownloadDemo", [], null, null, null, View_CaptainAppDownloadDemoWebview_0, RenderType_CaptainAppDownloadDemoWebview)), i1.ɵdid(1, 114688, null, 0, i2.CaptainAppDownloadDemoWebview, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CaptainAppDownloadDemoWebviewNgFactory = i1.ɵccf("captainAppDownloadDemo", i2.CaptainAppDownloadDemoWebview, View_CaptainAppDownloadDemoWebview_Host_0, {}, {}, []);
export { CaptainAppDownloadDemoWebviewNgFactory as CaptainAppDownloadDemoWebviewNgFactory };
