import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CaptainMerchandiseService } from './captain-merchandise.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-captain-merchandise',
  templateUrl: './captain-merchandise.component.html',
  styleUrls: ['./captain-merchandise.component.css']
})
export class CaptainMerchandiseComponent implements OnInit {
  constructor(
    private sanitizer: DomSanitizer,
    private captainMerchandiseService: CaptainMerchandiseService,
    private route: ActivatedRoute,
  ) {
  }

  public showBuyBox: boolean;
  public purchaseStatus: string;
  public purchaseInitiated: boolean;
  public showTC: boolean;
  public showLoader: boolean;
  public loaderText: string;
  public paymentStatus: any;
  public lang: string;
  public errorPageTexts: any;
  public tncs: string[];
  public bannerForHelmet: string;
  public merchandiseDetailsStatus: string;
  public merchandiseDetails: any;
  public orderDetails: any;
  public productPurchaseDetails: any;
  public hasExistingOrders: boolean;

  ngOnInit() {
    //set vars with default value
    this.lang = this.route.snapshot.paramMap.get('lang');
    this.lang = this.lang ? this.lang : `${environment.subscriptionDefaultLanguage}`;
    this.bannerForHelmet = `/assets/merchandise/buyHelmet/${this.lang}.svg`;
    this.showBuyBox = false;
    this.purchaseStatus = "";
    this.purchaseInitiated = false;
    this.showTC = false;
    this.showLoader = false;
    this.hasExistingOrders = false;
    this.errorPageTexts = {
      errorLoadingText: this.captainMerchandiseService.getErrorLoadingText(this.lang),
      errorPurchasingText: this.captainMerchandiseService.getErrorPurchasingText(this.lang),
      tryAgainText: this.captainMerchandiseService.getTryAgainText(this.lang),
      tryAgainButtonText: this.captainMerchandiseService.getTryAgainButtonText(this.lang),
      goBackButtonText: this.captainMerchandiseService.getGoBackButtonText(this.lang)
    };
    //improvement -- move to a dedicated order listing page
    //check if user has existing purchase
    this.handleLoader("");
    this.captainMerchandiseService.getOrderDetails(this.lang).subscribe(res => {
      //if yes- load order confirmation page
      let orderResponse = res['data'];
      if(orderResponse && orderResponse.length != 0){
        //we allow one successful purchase and will list one successful order
        this.orderDetails = orderResponse[0];
        this.hasExistingOrders = true;
        this.handleLoader("");
      }
      //check payment status
      else{
        this.paymentStatus = this.captainMerchandiseService.getPaymentStatus();
        if (this.paymentStatus && this.paymentStatus.razor_pay_transaction_status != "") {
          if (this.paymentStatus.razor_pay_transaction_status == "success") {
            //if razorpay success - complete order and show order listing page
            this.buy(this.paymentStatus.merchandise_id, this.paymentStatus.razor_pay_transaction_id, this.paymentStatus.transaction_id);
          } else if (this.paymentStatus.razor_pay_transaction_status == "failure") {
            //if razorpay failure - then show payment failure page that allows going back to product listing page
            this.purchaseInitiated = true;
            this.purchaseStatus = "ERROR";
            this.handleLoader("");
          }
        } else {
          //load product listing page on detail api success
          this.captainMerchandiseService.getProductDetails(this.lang).subscribe(res => {
            let products = res['data'];
            if( products && products.length > 0 ){
              this.merchandiseDetailsStatus = "SUCCESS";
              this.tncs = this.getTncs();
              this.merchandiseDetails = products[0]; //Currenlty we can only display one product
            }
            else{
              this.merchandiseDetailsStatus = "ERROR";
            }
            this.handleLoader("");
          }, error => {
            // load details api error page on api failure
            this.merchandiseDetailsStatus = "ERROR";
            this.handleLoader("");
          });
        }
      }
    },error => {
      //No existing orders
      this.merchandiseDetailsStatus = "ERROR";
      this.handleLoader("");
    });
  }

  getTncs(): string[] {
     const tnc = ["Order will be delivered in 3-5 days",
      "No Refund once the product is dispatched",
      "Visit the city office in case of any concerns"];
     return tnc;
  }


  handleLoader(text) {
    this.showLoader = !this.showLoader;
    this.loaderText = text;
  }

  buyPlan() {
    this.showBuyBox = true;
  }

  cancelBuy() {
    this.showBuyBox = false;
  }
  //Handles the purchase of a product
  async buy(productId = "",paymentId = "", orderId = "") {
    this.purchaseInitiated = true;
    let pid = productId || this.merchandiseDetails.id;
    if(!this.showLoader){
      this.handleLoader("");
    }
    let res = await this.retryTransaction(pid,paymentId, orderId);
  
    if (res.status == "success") {  
      this.purchaseStatus = "SUCCESS";
      this.productPurchaseDetails = res;
      this.showBuyBox = false;
      this.hideBackground("background-info", "none");
    } else if (res.data.status == "initiated") {
      this.productPurchaseDetails = res.data;
      if (this.paymentStatus && this.paymentStatus.razor_pay_transaction_status == "") {
        this.captainMerchandiseService.rechargeWallet(this.productPurchaseDetails);
        this.hideBackground("background-info", "none");
      } else {
        this.purchaseStatus = "ERROR";
      }
    } else {
      this.purchaseStatus = "ERROR";
      this.hideBackground("background-info", "none");
    }
    this.handleLoader("");
    this.showBuyBox = false;
  }

  async retryTransaction(productId = "" ,paymentId = "", orderId = "") {
    let timeOut = 4000;
    let tryCount = 1;
    if (paymentId != "" && orderId != "") {
      tryCount = parseInt(environment.merchandisePurchaseRetryCount);
    }
    const timer = ms => new Promise(res => setTimeout(res, ms));

    let response;
    for (var i = 1; i <= tryCount; i++) {
      await this.purchaseProduct(productId,paymentId, orderId, i).then((data: object) => {
        response = { ...data, funcStatus: "success" };
      }, async (error: object) => {
        response = { ...error, funcStatus: "error" };
        if (i != tryCount) {
          await timer(timeOut);
        }
        timeOut += 4000;
      });
      if (response.funcStatus == "success" || i == tryCount) {
        return response;
      }
    }
  }

  purchaseProduct(productId = "", paymentId = "", orderId = "", retryCount = 1) {
    return new Promise(async (resolve, reject) => {
      this.captainMerchandiseService.buyProduct(productId,paymentId, orderId, this.lang, retryCount)
        .subscribe(res => {
          resolve(
            res['data']
            );
        }, async (err) => {
          reject(err.error);
        });
    });
  }

  hideBackground(className, value) {
    const element = <HTMLElement>document.getElementsByClassName(className)[0];
    if (element) {
      element.style.display = value;
    }
  }

  viewTC() {
    this.showTC = true;
  }

  cancelTC() {
    this.showTC = false;
  }

  setDefaultBannerForMerchandise() {
    this.bannerForHelmet = `/assets/merchandise/buyHelmet/${environment.subscriptionDefaultLanguage}.svg`;
  }
}
