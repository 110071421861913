
<div *ngIf="!this.error && this.pdfUrl">
  <iframe  [src] = "safePdfUrl"  style="width: 100%; min-height: 100vh;"></iframe>
</div>
<div (click)="ctaClicked()">
  <img *ngIf="!this.error" class="imageUrl" src="{{this.pageUrl}}">
  <div *ngIf="this.error" class="error">
    <div>
      <img src="../../assets/error-screen.png">
    </div>

    <div>
      <p>404! Page not found!</p>
    </div>
  </div>
</div>

