/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./download-app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./download-app.component";
var styles_DownloadAppComponent = [i0.styles];
var RenderType_DownloadAppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DownloadAppComponent, data: {} });
export { RenderType_DownloadAppComponent as RenderType_DownloadAppComponent };
export function View_DownloadAppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "height-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "download-app-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "row positioning-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "center-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "rapido-logo"], ["src", "../../assets/Rapido.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "banner-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "font-size-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We miss you!"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "font-size-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["See what you're missing"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["class", "font-size-12 download-button"], ["href", "https://play.google.com/store/apps/details?id=com.rapido.rider&hl=en"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Download App "]))], null, null); }
export function View_DownloadAppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-download-app", [], null, null, null, View_DownloadAppComponent_0, RenderType_DownloadAppComponent)), i1.ɵdid(1, 114688, null, 0, i2.DownloadAppComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DownloadAppComponentNgFactory = i1.ɵccf("app-download-app", i2.DownloadAppComponent, View_DownloadAppComponent_Host_0, {}, {}, []);
export { DownloadAppComponentNgFactory as DownloadAppComponentNgFactory };
