import { Component, OnInit } from '@angular/core';
import { ViewIncentiveService } from './view-incentive.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-incentive',
  templateUrl: './view-incentive.component.html',
  styleUrls: ['./view-incentive.component.css']
})
export class ViewIncentiveComponent implements OnInit {

  public data: any;
  public incentiveId: string;
  public header: string;
  public daysToDisplay = [];
  public cardData = [];
  public dailyIncentive = false;
  public weeklyIncentive = false;
  constructor(private viewIncentiveService: ViewIncentiveService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.incentiveId = this.route.snapshot.paramMap.get('id');
    this.viewIncentiveService.getDetails(this.incentiveId).subscribe(res => {
      this.data = res['data'];
      this.getType();
      this.getCardDetails();
    }, error => {
      alert('Something went wrong. Please try again.');
    });
  }

  getType() {
    if (this.data && this.data.incentiveType) {
      const type = this.data.incentiveType;
      if (type === 'Daily') {
        this.header = 'Today\'s Incentive';
        this.dailyIncentive = true;
      } else if (type === 'Weekly Selectable') {
        this.header = 'Select an incentive for next week';
        this.weeklyIncentive = true;
      } else {
        this.header = 'This Week\'s Incentive';
        this.weeklyIncentive = true;
      }
    }
  }

  getCardDetails() {
    for (const x in this.data.goals) {
      if (x && this.data.goals[x]) {
        const tempCard = {};
        tempCard[x] = this.data.goals[x];
        this.cardData.push(tempCard);
      }
    }
  }
}




    //daily
    // this.data = {
    //   _id: '5cb06b33f8255f2a53fbf238',
    //   type: 'incentives',
    //   goals: {
    //     'Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday': [
    //       {
    //         timeSlot: [
    //           {
    //             fromTime: '2:00',
    //             toTime: '2:30'
    //           },
    //           {
    //             fromTime: '3:00',
    //             toTime: '3:30'
    //           }
    //         ],
    //         rules: [
    //           {
    //             order: 1,
    //             distance: 2,
    //             amount: 10,
    //             index: 0
    //           },
    //           {
    //             order: 3,
    //             distance: 4,
    //             amount: 20,
    //             index: 1
    //           }
    //         ]
    //       },
    //       {
    //         timeSlot: [
    //           {
    //             fromTime: '4:00',
    //             toTime: '4:30'
    //           },
    //           {
    //             fromTime: '6:00',
    //             toTime: '6:30'
    //           },
    //           {
    //             fromTime: '4:00',
    //             toTime: '4:30'
    //           },
    //           {
    //             fromTime: '6:00',
    //             toTime: '6:30'
    //           }
    //         ],
    //         rules: [
    //           {
    //             order: 10,
    //             distance: 20,
    //             amount: 100,
    //             index: 0
    //           },
    //           {
    //             order: 30,
    //             distance: 40,
    //             amount: 200,
    //             index: 1
    //           }
    //         ]
    //       }
    //     ]
    //   },
    //   incentiveType: 'Daily',
    //   logicalValue: 'AND',
    //   incentiveName: 'CHENNAI 13th - M1',
    //   ruleId: [
    //     '5cb06a6970dfac098b361b13'
    //   ],
    //   cities: [
    //     'Chennai'
    //   ],
    //   serviceType: [
    //     '572e29b0116b5db3057bd821'
    //   ],
    //   startDate: '2019-04-13',
    //   endDate: '2019-04-13',
    //   smsTemplate: 'Weekend Extraaa Incentives- 13-04-2019\n7 AM - 11 AM - Complete 5 rides and 25 kms - Rs 100.\n11AM - 5PM - Complete 7 rides and 30 kms - Rs 150. \n5 PM - 9 PM - Complete 5 Rides and 20 kms - Rs 75.\n9 PM - 11 PM - Complete 3 Rides and 12 kms - Rs 75.',
    //   createdDate: '2019-04-12',
    //   createdOn: 1555065642551,
    //   tnc: [
    //   ],
    //   active: true
    // };

    //weekly
    // this.data = {
    //   _id: '5cb06b33f8255f2a53fbf238',
    //   type: 'incentives',
      // goals: {
      //   'Wednesday, Thursday, Friday': [
      //     {
      //       timeSlot: [
      //         {
      //           fromTime: '12:00',
      //           toTime: '12:30'
      //         },
      //         {
      //           fromTime: '1:00',
      //           toTime: '1:30'
      //         }
      //       ],
      //       rules: [
      //         {
      //           order: 1,
      //           amount: 1,
      //           index: 0
      //         },
      //         {
      //           order: 2,
      //           amount: 2,
      //           index: 1
      //         },
      //         {
      //           order: 3,
      //           amount: 3,
      //           index: 3
      //         },
      //         {
      //           order: 4,
      //           amount: 4,
      //           index: 4
      //         }
      //       ]
      //     },
      //     {
      //       timeSlot: [
      //         {
      //           fromTime: '14:00',
      //           toTime: '14:30'
      //         },
      //         {
      //           fromTime: '13:00',
      //           toTime: '13:30'
      //         },
      //         {
      //           fromTime: '4:00',
      //           toTime: '4:30'
      //         },
      //         {
      //           fromTime: '5:00',
      //           toTime: '5:30'
      //         }
      //       ],
      //       rules: [
      //         {
      //           order: 10,
      //           distance: 20,
      //           amount: 100,
      //           index: 0
      //         },
      //         {
      //           order: 20,
      //           distance: 40,
      //           amount: 200,
      //           index: 1
      //         },
      //         {
      //           order: 30,
      //           amount: 300,
      //           index: 3
      //         },
      //         {
      //           order: 40,
      //           amount: 400,
      //           index: 4
      //         }
      //       ]
      //     }
      //   ],
      //   Saturday: [
      //     {
      //       timeSlot: [
      //         {
      //           fromTime: '4:00',
      //           toTime: '4:30'
      //         },
      //         {
      //           fromTime: '8:00',
      //           toTime: '8:30'
      //         }
      //       ],
      //       rules: [
      //         {
      //           distance: 10,
      //           amount: 100,
      //           index: 0
      //         },
      //         {
      //           distance: 12,
      //           amount: 200,
      //           index: 1
      //         }
      //       ]
      //     }
      //   ]
      // },
    //   incentiveType: 'Weekly',
    //   logicalValue: 'AND',
    //   incentiveName: 'CHENNAI 13th - M1',
    //   ruleId: [
    //     '5cb06a6970dfac098b361b13'
    //   ],
    //   cities: [
    //     'Chennai'
    //   ],
    //   serviceType: [
    //     '572e29b0116b5db3057bd821'
    //   ],
    //   startDate: '2019-04-13',
    //   endDate: '2019-04-13',
    //   smsTemplate: 'Weekend Extraaa Incentives- 13-04-2019\n7 AM - 11 AM - Complete 5 rides and 25 kms - Rs 100.\n11AM - 5PM - Complete 7 rides and 30 kms - Rs 150. \n5 PM - 9 PM - Complete 5 Rides and 20 kms - Rs 75.\n9 PM - 11 PM - Complete 3 Rides and 12 kms - Rs 75.',
    //   createdDate: '2019-04-12',
    //   createdOn: 1555065642551,
    //   tnc: [
    //   ],
    //   active: true
    // };