import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { FormsModule } from '@angular/forms'; // Import FormsModule if needed
import { Routes, RouterModule } from '@angular/router';
import { ViewIncentiveComponent } from '../app/view-incentive/view-incentive.component';
import { BookRapidoViaWhatsappComponent } from './book-rapido-via-whatsapp/book-rapido-via-whatsapp.component';
import { CaptainWhatsappRegistrationComponent } from './captain-whatsapp-registration/captain-whatsapp-registration.component';
import { Covid19Component } from './covid19/covid19.component';
import { DoesDontsComponent } from './does-donts/does-donts.component';
import { SuperSaverWeekComponent as SuperSaverWeekComponentV1 } from './ssw/v1/super-saver-week-component';
import { SuperSaverWeekComponent as SuperSaverWeekComponentV2 } from './ssw/v2/super-saver-week-component';
import { CaptainSubscriptionsComponent } from './captain-subscriptions/captain-subscriptions.component';
import { GenericWebviewComponent } from './generic-webview/generic-webview.component';
import { ShellCardRegistrationComponent } from './shell-card-registration/shell-card-registration.component';
import { RatingInfoWebview } from './ratings-page/rating-info-webview.component';
import { SafetyWebview } from './safety/safety-webview.component';
import { PowerPassComponent } from './power-pass/power-pass-component';
import { CaptainMerchandiseComponent } from './captain-merchandise/captain-merchandise.component';
import { SoftwareLicenceComponent } from './software-licences/software-licence.component';
import { RideGuaranteeWebview } from './rideGuarantee/rideGuarantee-webview.component';
import { GenericTemplateComponent } from './generic-template/generic-template.component';
import { CaptainAppDownloadDemoWebview } from './captainAppDownloadDemo/captainAppDownloadDemo-webview.component';

const routes: Routes = [
  {
    path: 'view/incentive/:id',
    component: ViewIncentiveComponent
  },
  {
    path: 'whatsappBooking',
    component: BookRapidoViaWhatsappComponent,
  },
  {
    path: 'beacaptain',
    component: CaptainWhatsappRegistrationComponent,
  },
  {
    path: 'covid-info',
    component: Covid19Component,
  },
  {
    path: 'does-donts',
    component: DoesDontsComponent,
  },
  {
    path: 'ssw-info',
    component: SuperSaverWeekComponentV1,
  },
  {
    path: 'v2/ssw-info',
    component: SuperSaverWeekComponentV2,
  },
  {
    path: 'captain-subscriptions',
    component: CaptainSubscriptionsComponent,
  },
  {
    path: 'captain-subscriptions/:lang',
    component: CaptainSubscriptionsComponent,
  },
  {
    path: 'page',
    component: GenericWebviewComponent,
  },
  {
    path: 'template',
    component: GenericTemplateComponent,
  },
  {
    path: 'shell-card',
    component: ShellCardRegistrationComponent,
  },
  {
    path: 'rating-info',
    component: RatingInfoWebview,
  },
  {
    path: 'safety',
    component: SafetyWebview,
  },
  {
    path: 'safety/:gender',
    component: SafetyWebview,
  },
  {
    path:'power-pass',
    component: PowerPassComponent,
  },
  {
    path: 'captain-merchandise',
    component: CaptainMerchandiseComponent,
  },
  {
    path: 'captain-merchandise/:lang',
    component: CaptainMerchandiseComponent,
  },
  {
    path: 'software-licence',
    component: SoftwareLicenceComponent,
  },
  {
    path: 'ride-guarantee',
    component: RideGuaranteeWebview
  },
  {
    path: 'captain-app-download',
    component: CaptainAppDownloadDemoWebview
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),BrowserModule,
    CommonModule,
    FormsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
