import { Injectable } from '@angular/core';
import { MyHttpClient } from '../../app/http-client.service';
import { environment } from 'src/environments/environment';


 declare var MyFunction;
@Injectable({
  providedIn: 'root'
})
export class CaptainMerchandiseService {

  constructor(private http: MyHttpClient) { }

  server = environment.captainServer;
  buyProductApi = this.server + '/merchandise/purchase/';
  getEligibleProductsApi = this.server + '/merchandise';
  getOrderDetailsApi = this.server + '/merchandise/orders'
  public razor_pay_transaction_status: any
	public razor_pay_transaction_id: any
	public transaction_id: any

  getToken() {
    return MyFunction && MyFunction.getAuthToken();
  }

  getProductDetails(lang) {
    return this.http.executeGetRequest(this.getEligibleProductsApi, this.getToken(), lang);
  }

  getOrderDetails(lang) {
    return this.http.executeGetRequest(this.getOrderDetailsApi, this.getToken(), lang);
  }

  buyProduct(productId, paymentId = "", orderId ="", lang, retryCount) {
    let url = this.buyProductApi + productId;
    let body = {};
    if (paymentId != "" && orderId != "") {
      body = {
        paymentId: paymentId,
        orderId: orderId,
      };
    }
    body["retryCount"] = retryCount

    return this.http.executePostRequest(url, body, {}, {}, this.getToken(), lang);
  }
  getPaymentStatus() {
    if (MyFunction && MyFunction.getMerchandisePaymentStatus) {
      let data = MyFunction && MyFunction.getMerchandisePaymentStatus && MyFunction.getMerchandisePaymentStatus();
      try {
        return JSON.parse(data);
      } catch {
        return null;
      }
    }
    return null;
  }
  
  rechargeWallet(buyDetails) {
    let page = window.location.href;
    MyFunction && MyFunction.openRechargeWalletForMerchandise && 
    MyFunction.openRechargeWalletForMerchandise(
      buyDetails.purchasePrice,
       "Recharge wallet", 
       buyDetails.orderId, 
       buyDetails.productId, 
       page, 
       "wallet_recharge", 
       "captainMerchandiseId"
    );
  }


  getLoadingPlanText(lang) {
    switch (lang) {
      case "en": return "Loading your Plan ...";
      case "hi": return "प्लान लोड हो रहा है ...";
      case "te": return "మీ ప్లాన్ లోడ్ అవుతోంది ...";
      case "kn": return "ನಿಮ್ಮ ಪ್ಲಾನ್ ಲೋಡ್ ಆಗುತ್ತಿದೆ ...";
      case "ta": return "உங்கள் திட்டத்தை ஏற்றுகிறது ...";
      case "bn": return "প্ল্যান লোড হচ্ছে ...";
    }
  }

  getPurchasingPlanText(lang) {
    switch (lang) {
      case "en": return "Purchasing Subscription Plan ...";
      case "hi": return "प्लान खरीदा जा रहा है ...";
      case "te": return "సబ్‌స్క్రిప్‌షన్ ప్లాన్ కొనుగోలు చెయ్యబడుతోంది ...";
      case "kn": return "ಸಬ್‌ಸ್ಕ್ರಿಪ್ಶನ್ ಪ್ಲಾನನ್ನು ಖರೀದಿಸಲಾಗುತ್ತಿದೆ...";
      case "ta": return "சந்தா திட்டம் வாங்குதல் ...";
      case "bn": return "সাবস্ক্রিপশন প্ল্যান কেনা হচ্ছে ...";
    }
  }

  getErrorLoadingText(lang) {
    switch (lang) {
      case "en": return "Sorry, we were not able to load the <br> page right now";
      case "hi": return "हमे खेद है , हम अभी पेज <br> खोलने करने में असमर्थ है    ";
      case "te": return "క్షమించండి! ఈ పేజీ ఇప్పుడు <br> లోడ్ చెయ్యలేకపోతున్నాము";
      case "kn": return "ಕ್ಷಮಿಸಿ, ಇದೀಗ <br> ಪುಟವನ್ನು ಲೋಡ್ ಮಾಡಲು ಸಾಧ್ಯವಾಗಲಿಲ್ಲ";
      case "ta": return "மன்னிக்கவும், இப்போது <br> பக்கத்தை ஏற்ற முடியவில்லை";
      case "bn": return "দুঃখিত, <br> পেজটা এই মুহূর্তে লোড হচ্ছে না";
    }
  }

  getTryAgainText(lang) {
    switch (lang) {
      case "en": return "Please try again...";
      case "hi": return "कृपया वापस प्रयास करे...";
      case "te": return "దయచేసి తరువాత ప్రయత్నించండి...";
      case "kn": return "ದಯವಿಟ್ಟು ಪುನಃ ಪ್ರಯತ್ನಿಸಿ...";
      case "ta": return "தயவு செய்து மீண்டும் முயற்சிக்கவும்...";
      case "bn": return "আবার চেষ্টা করুন...";
    }
  }

  getTryAgainButtonText(lang) {
    switch (lang) {
      case "en": return "Try Again";
      case "hi": return "वापस प्रयास करे";
      case "te": return "మళ్ళీ ప్రయత్నించండి";
      case "kn": return "ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ";
      case "ta": return "மீண்டும் முயற்சி செய்";
      case "bn": return "আবার চেষ্টা করুন";
    }
  }

  getGoBackButtonText(lang) {
    switch (lang) {
      case "en": return "Go Back";
      case "hi": return "पीछे जाए";
      case "te": return "వెనక్కి వెళ్లండి";
      case "kn": return "ಹಿಂತಿರುಗಿ";
      case "ta": return "திரும்பி செல்";
      case "bn": return "ফিরে যান";
    }
  }

  getErrorPurchasingText(lang) {
    switch (lang) {
      case "en": return "Sorry, we were not able to complete <br> your purchase";
      case "hi": return "हमे खेद है आपकी खरीद <br> सफल नहीं रही ";
      case "te": return "క్షమించండి! మీ కొనుగోలు <br> పూర్తి చెయ్యలేకపోయాము";
      case "kn": return "ಕ್ಷಮಿಸಿ, ನಿಮ್ಮ ಖರೀದಿಯನ್ನು <br> ಪೂರ್ಣಗೊಳಿಸಲು ಸಾಧ್ಯವಾಗಲಿಲ್ಲ";
      case "ta": return "மன்னிக்கவும், நாங்கள் உங்கள் கொள்முதல் <br> முடிக்க முடியவில்லை";
      case "bn": return "দুঃখিত, আমরা আপনার কেনা <br> সম্পূর্ণ করতে পারিনি";
    }
  }
}