<div class="height-100">
  <div class="download-app-banner">
    <div class="row positioning-banner">
      <div class="center-content">
        <img class="rapido-logo" src="../../assets/Rapido.png">
        <div class="banner-text">
          <span class="font-size-14">We miss you!</span>
          <br>
          <span class="font-size-12">See what you're missing</span>
        </div>
      </div>
      <div>
        <a href="https://play.google.com/store/apps/details?id=com.rapido.rider&hl=en" class="font-size-12 download-button"> Download App </a>
      </div>
    </div>
  </div>
</div>