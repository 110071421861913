import {Component, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'captainAppDownloadDemo',
    templateUrl: './captainAppDownloadDemo-webview.component.html',
    styleUrls: ['./captainAppDownloadDemo-webview.component.css']
})

export class CaptainAppDownloadDemoWebview implements OnInit {
    public ytVideoSrc: string;
    ngOnInit(): void {
        this.updateValues();
    }
    updateValues() {
        this.ytVideoSrc = 'https://www.youtube.com/embed/CKpbdCciELk?si=oIYhH8viW9xsUHC8'
    }
}
