import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MyHttpClient } from '../../app/http-client.service';

@Injectable({
    providedIn: 'root'
})
export class SuperSaverWeekService {

    constructor(private http: MyHttpClient) { }

    server = `${environment.server}`;
    getConfigs = this.server + '/api/ssw-configs';

    getSuperSaverWeekConfigs() {
        console.log(this.getConfigs)
        return this.http.get(this.getConfigs).pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        return throwError(error);
    }
}