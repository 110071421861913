/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-webview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./generic-webview.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/platform-browser";
var styles_GenericWebviewComponent = [i0.styles];
var RenderType_GenericWebviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericWebviewComponent, data: {} });
export { RenderType_GenericWebviewComponent as RenderType_GenericWebviewComponent };
function View_GenericWebviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["style", "width: 100%; min-height: 100vh;"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.safePdfUrl; _ck(_v, 1, 0, currVal_0); }); }
function View_GenericWebviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "imageUrl"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.pageUrl, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_GenericWebviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "error"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "../../assets/error-screen.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["404! Page not found!"]))], null, null); }
export function View_GenericWebviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericWebviewComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ctaClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericWebviewComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenericWebviewComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.error && _co.pdfUrl); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.error; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.error; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_GenericWebviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-webview", [], null, null, null, View_GenericWebviewComponent_0, RenderType_GenericWebviewComponent)), i1.ɵdid(1, 114688, null, 0, i3.GenericWebviewComponent, [i4.ActivatedRoute, i4.Router, i5.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericWebviewComponentNgFactory = i1.ɵccf("app-generic-webview", i3.GenericWebviewComponent, View_GenericWebviewComponent_Host_0, {}, {}, []);
export { GenericWebviewComponentNgFactory as GenericWebviewComponentNgFactory };
