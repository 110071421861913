import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ViewIncentiveComponent } from './view-incentive/view-incentive.component';
import { InfoHeaderComponent } from './info-header/info-header.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { CardSeparatorComponent } from './card-separator/card-separator.component';
import { InfoCardsComponent } from './info-cards/info-cards.component';
import { MyHttpClient } from './http-client.service';
import {TimeUtils} from './time-utils'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BookRapidoViaWhatsappComponent } from './book-rapido-via-whatsapp/book-rapido-via-whatsapp.component';
import { CaptainWhatsappRegistrationComponent } from './captain-whatsapp-registration/captain-whatsapp-registration.component';
import { Covid19Component } from './covid19/covid19.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { DoesDontsComponent } from './does-donts/does-donts.component';
import { SuperSaverWeekComponent as  SuperSaverWeekComponentV1} from './ssw/v1/super-saver-week-component';
import { SuperSaverWeekComponent as  SuperSaverWeekComponentV2 } from './ssw/v2/super-saver-week-component';
import { SuperSaverWeekService } from './ssw/super-saver-week-service';
import { SafeurlPipe } from './ssw/utils';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CaptainSubscriptionsComponent } from './captain-subscriptions/captain-subscriptions.component';
import { GenericWebviewComponent } from './generic-webview/generic-webview.component';
import { ShellCardRegistrationComponent } from './shell-card-registration/shell-card-registration.component';
import { RatingInfoWebview } from './ratings-page/rating-info-webview.component';
import { SafetyWebview } from './safety/safety-webview.component';
import { PowerPassComponent } from './power-pass/power-pass-component';
import { CaptainMerchandiseComponent } from './captain-merchandise/captain-merchandise.component';
import { SoftwareLicenceComponent } from './software-licences/software-licence.component';
import { RideGuaranteeWebview } from './rideGuarantee/rideGuarantee-webview.component';
import { GenericTemplateComponent } from './generic-template/generic-template.component';
import { CaptainAppDownloadDemoWebview } from './captainAppDownloadDemo/captainAppDownloadDemo-webview.component';

@NgModule({
  declarations: [
    AppComponent,
    ViewIncentiveComponent,
    InfoHeaderComponent,
    DownloadAppComponent,
    CardSeparatorComponent,
    InfoCardsComponent,
    BookRapidoViaWhatsappComponent,
    CaptainWhatsappRegistrationComponent,
    Covid19Component,
    DoesDontsComponent,
    SuperSaverWeekComponentV1,
    SuperSaverWeekComponentV2,
    SafeurlPipe,
    CaptainSubscriptionsComponent,
    GenericWebviewComponent,
    ShellCardRegistrationComponent,
    RatingInfoWebview,
    SafetyWebview,
    PowerPassComponent,
    CaptainMerchandiseComponent,
    SoftwareLicenceComponent,
    RideGuaranteeWebview,
    GenericTemplateComponent,
    CaptainAppDownloadDemoWebview
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    FormsModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    Ng4LoadingSpinnerModule.forRoot(),
    ToastrModule.forRoot()
  ],
  providers: [
    MyHttpClient,
    TimeUtils,
    SuperSaverWeekService
  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
