import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MyHttpClient } from '../http-client.service';
function getToken() {
    try {
        return MyFunction && MyFunction.getAuthToken();
    }
    catch (_a) {
        return "";
    }
}
var ShellCardRegistrationComponent = /** @class */ (function () {
    function ShellCardRegistrationComponent(http) {
        this.http = http;
        this.accessTokenAPI = environment.captainServer + "/api/shell/token";
        this.shellRedirectUrl = environment.shellRedirectURL;
        this.error = "true";
    }
    ShellCardRegistrationComponent.prototype.ngOnInit = function () {
        this.redirect();
    };
    ShellCardRegistrationComponent.prototype.redirectToShellPage = function (token) {
        var form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", this.shellRedirectUrl);
        form.setAttribute("style", "display:none");
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", "access_token");
        hiddenField.setAttribute("value", token);
        form.appendChild(hiddenField);
        document.body.appendChild(form);
        form.submit();
    };
    ShellCardRegistrationComponent.prototype.redirect = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.error = undefined;
                        return [4 /*yield*/, this.generateTokenAndRedirect()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.error = error_1;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ShellCardRegistrationComponent.prototype.generateTokenAndRedirect = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var accessToken;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.executeGetRequest(this.accessTokenAPI, getToken())
                            .toPromise()];
                    case 1:
                        accessToken = (_a.sent()).token;
                        this.redirectToShellPage(accessToken);
                        return [2 /*return*/];
                }
            });
        });
    };
    return ShellCardRegistrationComponent;
}());
export { ShellCardRegistrationComponent };
