import { Injectable } from '@angular/core';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

@Injectable()
export class TimeUtils {

    getDateSuffix = (date: Date) => {
        let day = date.getDate()
        if (day === 11 || day === 12 || day === 13) return "th";
        else if ((day % 10) == 1) return "st";
        else if ((day % 10) == 2) return "nd";
        else if ((day % 10) == 3) return "rd";
        else return "th";
    }

    getDaysBetweenTwoDays(smallDate: Date, largeDate: Date): number {
        return Math.ceil((largeDate.valueOf() - smallDate.valueOf()) / MS_PER_DAY)
    }

    getElapsedTimeFrom(tillDate: Date) {
        let now = new Date()
        let reaminingTime = tillDate.valueOf() - now.valueOf()
        if (reaminingTime < 0) {
            return {
                hour: ("0" + 0).slice(-2),
                minutes: ("0" + 0).slice(-2),
                seconds: ("0" + 0).slice(-2)
            }
        }
        const time = {
            hour: ("0" + Math.floor((reaminingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).slice(-2),
            minutes: ("0" + Math.floor(Math.floor((reaminingTime % (1000 * 60 * 60)) / (1000 * 60)))).slice(-2),
            seconds: ("0" + Math.floor((reaminingTime % (1000 * 60)) / 1000)).slice(-2)
        }
        return time
    }
}
