<div class="card">
  <div class="card-header">
    <div class="header-text">Earn upto
      <fa-icon class="font-size-20 margin-left-1" [icon]="rupee"></fa-icon>
      {{totalAmount}}
    </div>
    <div class="sub-header-text">{{finalMilestone}}</div>
  </div>
  <div class="padding-10-16">
    <div *ngFor="let rule of dataForCards; let i = index">
      <div class="row">
        <div class="bullet"></div>
        <span class="statement">
          {{rule.statement}}
        </span>
        <span class="money pull-right">
          <div class="text-align">
            <fa-icon class="font-size-15 margin-right-1" [icon]="rupee"></fa-icon>
            {{rule.money}}
          </div>
        </span>
      </div>
      <div [ngClass]="{'line': i !== (totalRules - 1)}"></div>
    </div>
  </div>
</div>