/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-separator.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../info-cards/info-cards.component.ngfactory";
import * as i4 from "../info-cards/info-cards.component";
import * as i5 from "./card-separator.component";
var styles_CardSeparatorComponent = [i0.styles];
var RenderType_CardSeparatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardSeparatorComponent, data: {} });
export { RenderType_CardSeparatorComponent as RenderType_CardSeparatorComponent };
function View_CardSeparatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "otherData"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.timeslots; _ck(_v, 1, 0, currVal_0); }); }
function View_CardSeparatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "hr-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "hr-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "hr-line"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardSeparatorComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-info-cards", [], null, null, null, i3.View_InfoCardsComponent_0, i3.RenderType_InfoCardsComponent)), i1.ɵdid(8, 638976, null, 0, i4.InfoCardsComponent, [], { data: [0, "data"], type: [1, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit.timeslots; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.data; var currVal_3 = _co.type; _ck(_v, 8, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.header; _ck(_v, 3, 0, currVal_0); }); }
export function View_CardSeparatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardSeparatorComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CardSeparatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-separator", [], null, null, null, View_CardSeparatorComponent_0, RenderType_CardSeparatorComponent)), i1.ɵdid(1, 638976, null, 0, i5.CardSeparatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardSeparatorComponentNgFactory = i1.ɵccf("app-card-separator", i5.CardSeparatorComponent, View_CardSeparatorComponent_Host_0, { data: "data", type: "type" }, {}, []);
export { CardSeparatorComponentNgFactory as CardSeparatorComponentNgFactory };
