import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CaptainMerchandiseService } from './captain-merchandise.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
var CaptainMerchandiseComponent = /** @class */ (function () {
    function CaptainMerchandiseComponent(sanitizer, captainMerchandiseService, route) {
        this.sanitizer = sanitizer;
        this.captainMerchandiseService = captainMerchandiseService;
        this.route = route;
    }
    CaptainMerchandiseComponent.prototype.ngOnInit = function () {
        var _this = this;
        //set vars with default value
        this.lang = this.route.snapshot.paramMap.get('lang');
        this.lang = this.lang ? this.lang : "" + environment.subscriptionDefaultLanguage;
        this.bannerForHelmet = "/assets/merchandise/buyHelmet/" + this.lang + ".svg";
        this.showBuyBox = false;
        this.purchaseStatus = "";
        this.purchaseInitiated = false;
        this.showTC = false;
        this.showLoader = false;
        this.hasExistingOrders = false;
        this.errorPageTexts = {
            errorLoadingText: this.captainMerchandiseService.getErrorLoadingText(this.lang),
            errorPurchasingText: this.captainMerchandiseService.getErrorPurchasingText(this.lang),
            tryAgainText: this.captainMerchandiseService.getTryAgainText(this.lang),
            tryAgainButtonText: this.captainMerchandiseService.getTryAgainButtonText(this.lang),
            goBackButtonText: this.captainMerchandiseService.getGoBackButtonText(this.lang)
        };
        //improvement -- move to a dedicated order listing page
        //check if user has existing purchase
        this.handleLoader("");
        this.captainMerchandiseService.getOrderDetails(this.lang).subscribe(function (res) {
            //if yes- load order confirmation page
            var orderResponse = res['data'];
            if (orderResponse && orderResponse.length != 0) {
                //we allow one successful purchase and will list one successful order
                _this.orderDetails = orderResponse[0];
                _this.hasExistingOrders = true;
                _this.handleLoader("");
            }
            //check payment status
            else {
                _this.paymentStatus = _this.captainMerchandiseService.getPaymentStatus();
                if (_this.paymentStatus && _this.paymentStatus.razor_pay_transaction_status != "") {
                    if (_this.paymentStatus.razor_pay_transaction_status == "success") {
                        //if razorpay success - complete order and show order listing page
                        _this.buy(_this.paymentStatus.merchandise_id, _this.paymentStatus.razor_pay_transaction_id, _this.paymentStatus.transaction_id);
                    }
                    else if (_this.paymentStatus.razor_pay_transaction_status == "failure") {
                        //if razorpay failure - then show payment failure page that allows going back to product listing page
                        _this.purchaseInitiated = true;
                        _this.purchaseStatus = "ERROR";
                        _this.handleLoader("");
                    }
                }
                else {
                    //load product listing page on detail api success
                    _this.captainMerchandiseService.getProductDetails(_this.lang).subscribe(function (res) {
                        var products = res['data'];
                        if (products && products.length > 0) {
                            _this.merchandiseDetailsStatus = "SUCCESS";
                            _this.tncs = _this.getTncs();
                            _this.merchandiseDetails = products[0]; //Currenlty we can only display one product
                        }
                        else {
                            _this.merchandiseDetailsStatus = "ERROR";
                        }
                        _this.handleLoader("");
                    }, function (error) {
                        // load details api error page on api failure
                        _this.merchandiseDetailsStatus = "ERROR";
                        _this.handleLoader("");
                    });
                }
            }
        }, function (error) {
            //No existing orders
            _this.merchandiseDetailsStatus = "ERROR";
            _this.handleLoader("");
        });
    };
    CaptainMerchandiseComponent.prototype.getTncs = function () {
        var tnc = ["Order will be delivered in 3-5 days",
            "No Refund once the product is dispatched",
            "Visit the city office in case of any concerns"];
        return tnc;
    };
    CaptainMerchandiseComponent.prototype.handleLoader = function (text) {
        this.showLoader = !this.showLoader;
        this.loaderText = text;
    };
    CaptainMerchandiseComponent.prototype.buyPlan = function () {
        this.showBuyBox = true;
    };
    CaptainMerchandiseComponent.prototype.cancelBuy = function () {
        this.showBuyBox = false;
    };
    //Handles the purchase of a product
    CaptainMerchandiseComponent.prototype.buy = function (productId, paymentId, orderId) {
        if (productId === void 0) { productId = ""; }
        if (paymentId === void 0) { paymentId = ""; }
        if (orderId === void 0) { orderId = ""; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pid, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.purchaseInitiated = true;
                        pid = productId || this.merchandiseDetails.id;
                        if (!this.showLoader) {
                            this.handleLoader("");
                        }
                        return [4 /*yield*/, this.retryTransaction(pid, paymentId, orderId)];
                    case 1:
                        res = _a.sent();
                        if (res.status == "success") {
                            this.purchaseStatus = "SUCCESS";
                            this.productPurchaseDetails = res;
                            this.showBuyBox = false;
                            this.hideBackground("background-info", "none");
                        }
                        else if (res.data.status == "initiated") {
                            this.productPurchaseDetails = res.data;
                            if (this.paymentStatus && this.paymentStatus.razor_pay_transaction_status == "") {
                                this.captainMerchandiseService.rechargeWallet(this.productPurchaseDetails);
                                this.hideBackground("background-info", "none");
                            }
                            else {
                                this.purchaseStatus = "ERROR";
                            }
                        }
                        else {
                            this.purchaseStatus = "ERROR";
                            this.hideBackground("background-info", "none");
                        }
                        this.handleLoader("");
                        this.showBuyBox = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    CaptainMerchandiseComponent.prototype.retryTransaction = function (productId, paymentId, orderId) {
        if (productId === void 0) { productId = ""; }
        if (paymentId === void 0) { paymentId = ""; }
        if (orderId === void 0) { orderId = ""; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var timeOut, tryCount, timer, response, i;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        timeOut = 4000;
                        tryCount = 1;
                        if (paymentId != "" && orderId != "") {
                            tryCount = parseInt(environment.merchandisePurchaseRetryCount);
                        }
                        timer = function (ms) { return new Promise(function (res) { return setTimeout(res, ms); }); };
                        i = 1;
                        _a.label = 1;
                    case 1:
                        if (!(i <= tryCount)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.purchaseProduct(productId, paymentId, orderId, i).then(function (data) {
                                response = tslib_1.__assign({}, data, { funcStatus: "success" });
                            }, function (error) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            response = tslib_1.__assign({}, error, { funcStatus: "error" });
                                            if (!(i != tryCount)) return [3 /*break*/, 2];
                                            return [4 /*yield*/, timer(timeOut)];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2:
                                            timeOut += 4000;
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 2:
                        _a.sent();
                        if (response.funcStatus == "success" || i == tryCount) {
                            return [2 /*return*/, response];
                        }
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CaptainMerchandiseComponent.prototype.purchaseProduct = function (productId, paymentId, orderId, retryCount) {
        var _this = this;
        if (productId === void 0) { productId = ""; }
        if (paymentId === void 0) { paymentId = ""; }
        if (orderId === void 0) { orderId = ""; }
        if (retryCount === void 0) { retryCount = 1; }
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.captainMerchandiseService.buyProduct(productId, paymentId, orderId, this.lang, retryCount)
                    .subscribe(function (res) {
                    resolve(res['data']);
                }, function (err) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        reject(err.error);
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/];
            });
        }); });
    };
    CaptainMerchandiseComponent.prototype.hideBackground = function (className, value) {
        var element = document.getElementsByClassName(className)[0];
        if (element) {
            element.style.display = value;
        }
    };
    CaptainMerchandiseComponent.prototype.viewTC = function () {
        this.showTC = true;
    };
    CaptainMerchandiseComponent.prototype.cancelTC = function () {
        this.showTC = false;
    };
    CaptainMerchandiseComponent.prototype.setDefaultBannerForMerchandise = function () {
        this.bannerForHelmet = "/assets/merchandise/buyHelmet/" + environment.subscriptionDefaultLanguage + ".svg";
    };
    return CaptainMerchandiseComponent;
}());
export { CaptainMerchandiseComponent };
