import { Component, OnInit } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { TimeUtils } from '../../time-utils';
import { SuperSaverWeekService } from '../super-saver-week-service';

@Component({
  selector: 'app-ssw',
  templateUrl: './super-saver-week-component.html',
  styleUrls: ['./super-saver-week-component.css']
})

export class SuperSaverWeekComponent implements OnInit {

  public configs: any
  public superSaverIcon: string
  public brandIcon: string
  public backgroundImage: string
  public sswIsActive: boolean
  public sswStartDate: Date
  public sswEndDate: Date
  public isOneDayReamining: boolean
  public reaminingTime: Object
  public nextSswStartDate: Date
  public cashBackUpto: number
  public sharingText: string
  public status: string
  public statusImagePath: string
  public prize: Object
  public tnc: string[]
  timeUtils: TimeUtils
  service: SuperSaverWeekService;
  public loaded: boolean;
  public postSevenDays: boolean;
  private spinnerService: Ng4LoadingSpinnerService;
  public showTemplate = false

  constructor(timeUtils: TimeUtils, service: SuperSaverWeekService, spinnerService: Ng4LoadingSpinnerService) {
    this.spinnerService = spinnerService
    this.timeUtils = timeUtils
    this.service = service
  }

  ngOnInit(): void {
    this.spinnerService.show()
    this.service.getSuperSaverWeekConfigs()
      .subscribe(res => {
        this.configs = res;
        this.updateValues()
      }, () => {
        this.spinnerService.hide()
        alert('Something went wrong. Please try again.');
      });
  }

  updateValues() {
    this.sswStartDate = new Date(this.configs.startDate)
    this.sswEndDate = new Date(this.configs.endDate)
    this.nextSswStartDate = new Date(this.configs.nextSswStartDate)

    this.sswIsActive = isSuperSaverWeekActive(this.sswStartDate, this.sswEndDate)


    let daysReamining = this.timeUtils.getDaysBetweenTwoDays(new Date(), this.sswStartDate)

    this.isOneDayReamining = daysReamining === 1
    this.superSaverIcon = '/assets/ssw/ssw.svg'
    this.cashBackUpto = this.configs.cashBackUpto
    this.prize = this.configs.prize
    this.tnc = this.configs.tnc
    this.sharingText = this.configs.sharingText
    this.postSevenDays = this.timeUtils.getDaysBetweenTwoDays(this.sswEndDate, new Date()) < 8

    if (this.isOneDayReamining) {
      setInterval(() => {
        this.reaminingTime = this.timeUtils.getElapsedTimeFrom(this.sswStartDate)
        this.spinnerService.hide()
        this.showTemplate = true
      }, 1000)
      this.setInActiveAssets()
    } else if (this.sswIsActive) {
      this.setActiveAssets();
      this.spinnerService.hide()
      this.showTemplate = true
    } else if (!this.sswIsActive) {
      this.setInActiveAssets()
      this.spinnerService.hide()
      this.showTemplate = true
    }
  }

  redirectToWhatsapp = () => {
    window.open(
      `whatsapp://send?text=${this.sharingText}`,
      '_blank'
    );
  }

  setInActiveAssets() {
    this.brandIcon = '/assets/ssw/brand-inactive.svg'
    this.backgroundImage = '/assets/ssw/background-inactive.svg'
    this.status = "Ended"
    this.statusImagePath = "/assets/ssw/ended.svg"
  }

  setActiveAssets() {
    this.brandIcon = '/assets/ssw/brand-active.svg'
    this.backgroundImage = '/assets/ssw/background-active.svg'
    this.status = "Live now"
    this.statusImagePath = "/assets/ssw/live.svg"
  }
}

function isSuperSaverWeekActive(startDate: Date, endDate: Date): boolean {
  var today = new Date();
  endDate.setHours(23, 59, 59, 0)
  return (today.valueOf() >= startDate.valueOf() && today.valueOf() <= endDate.valueOf())
}
