<div class="template" [ngClass]="{ 'dark-mode': darkMode }">
  <div *ngIf="!error && configuration" class="imageUrl">
    <div *ngIf="configuration.assetUrl" class="asset-wrapper">
      <img class="asset" [src]="darkMode && configuration.darkAssetUrl ? configuration.darkAssetUrl : configuration.assetUrl">
    </div>
    <div class="container">
      <div class="header">
        <div *ngIf="configuration.titleText" id="title" class="title">{{renderTitle(configuration.titleText)}}</div>
        <div *ngIf="configuration.description" class="subtext">{{configuration.description}}</div>
      </div>
      <div class="value-propositions" *ngIf="configuration.valuePropositions">
        <div *ngFor="let proposition of configuration.valuePropositions" class="value-proposition">
          <div *ngIf="hasVpIcons && proposition.iconUrl" class="vp-icon">
            <img class="vp-icon-asset" [src]="darkMode && proposition.darkIconUrl ? proposition.darkIconUrl : proposition.iconUrl">
          </div>
          <div class="vp-text">
            <div class="vp-title">{{proposition.title}}</div>
            <div class="vp-description">{{proposition.description}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="hasLink" class="link-wrapper">
        <div *ngIf="configuration.link.leadingIcon" class="leading-icon flex-align-center">
          <img class="link-icon-asset" [src]="darkMode && configuration.link.darkLeadingIcon ? configuration.link.darkLeadingIcon : configuration.link.leadingIcon">
        </div>
        <a href="{{configuration.link.url}}" class="link-text">{{configuration.link.displayText}}</a>
        <div *ngIf="configuration.link.trailingIcon" class="trailing-icon flex-align-center">
          <img class="link-icon-asset" [src]="darkMode && configuration.link.darkTrailingIcon ? configuration.link.darkTrailingIcon : configuration.link.trailingIcon">
        </div>
      </div>
    </div>

    <div class="bounce" *ngIf="showScrollIndication">
      <img *ngIf="!darkMode" class="bouncing-icon" src="../../assets/expand_more.svg">
      <img *ngIf="darkMode" class="bouncing-icon" src="../../assets/chevron_right.svg">
    </div>

  </div>

  
  <div *ngIf="error" class="error">
    <div>
      <img src="../../assets/template-error-screen.svg">
    </div>
  </div>
</div>
