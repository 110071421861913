import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-info-cards',
  templateUrl: './info-cards.component.html',
  styleUrls: ['./info-cards.component.css']
})
export class InfoCardsComponent implements OnInit, OnChanges {
  rupee = faRupeeSign;
  public dataForCards = [];
  public totalAmount = 0;
  public finalMilestone = '';
  public totalRules = 0;
  public totalMoneyMax = 0;
  @Input() data: any;
  @Input() type: any;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.constructCardTable();
    }
  }

  ngOnInit() {
  }

  constructCardTable() {
    for (const data in this.data) {
      if (data && this.data[data]) {
        if (data === 'rules') {
          for (const rule in this.data[data]) {
            if (rule) {
              let statement = '';
              let money = 0;
              let order = false;
              let distance = false;
              this.totalRules++;
              if (this.data[data][rule].order) {
                order = true;
              }
              if (this.data[data][rule].distance) {
                distance = true;
              }
              if (order && distance) {
                statement = 'Complete ' + this.data[data][rule].order + ' ride(s) & ' + this.data[data][rule].distance + ' km, get';
                money = this.data[data][rule].amount;
                this.calculateTotalMoney(this.data[data][rule].amount);
                const dataCard = {statement, money};
                this.dataForCards.push(dataCard);
                this.finalMilestone = 'by completing ' + this.data[data][rule].order +
                      ' ride(s) & ' + this.data[data][rule].distance + ' km';
              } else if (order && !distance) {
                statement = 'Complete ' + this.data[data][rule].order + ' ride(s), get';
                money = this.data[data][rule].amount;
                this.calculateTotalMoney(this.data[data][rule].amount);
                const dataCard = {statement, money};
                this.dataForCards.push(dataCard);
                this.finalMilestone = 'by completing ' + this.data[data][rule].order + ' ride(s)';
              }
              if (distance && !order) {
                statement = 'Complete ' + this.data[data][rule].distance + ' km, get';
                money = this.data[data][rule].amount;
                this.calculateTotalMoney(this.data[data][rule].amount);
                const dataCard = {statement, money};
                this.dataForCards.push(dataCard);
                this.finalMilestone = 'by completing ' + this.data[data][rule].distance + ' km';
              }
            }
          }
        }
      }
    }
  }

  calculateTotalMoney(amount) {
    if (this.type === 'Weekly Selectable') {
      if (this.totalAmount < amount) {
        this.totalAmount = amount;
      }
    } else {
      this.totalAmount += amount;
    }
  }

}
