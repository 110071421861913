import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MyHttpClient } from '../../app/http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
var SuperSaverWeekService = /** @class */ (function () {
    function SuperSaverWeekService(http) {
        this.http = http;
        this.server = "" + environment.server;
        this.getConfigs = this.server + '/api/ssw-configs';
    }
    SuperSaverWeekService.prototype.getSuperSaverWeekConfigs = function () {
        console.log(this.getConfigs);
        return this.http.get(this.getConfigs).pipe(catchError(this.handleError));
    };
    SuperSaverWeekService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        }
        else {
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        return throwError(error);
    };
    SuperSaverWeekService.ngInjectableDef = i0.defineInjectable({ factory: function SuperSaverWeekService_Factory() { return new SuperSaverWeekService(i0.inject(i1.MyHttpClient)); }, token: SuperSaverWeekService, providedIn: "root" });
    return SuperSaverWeekService;
}());
export { SuperSaverWeekService };
