import {Component, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'safety',
    templateUrl: './safety-webview.component.html',
    styleUrls: ['./safety-webview.component.css']
})

export class SafetyWebview implements OnInit {

    constructor(
        private route: ActivatedRoute,
    ) {
    }
    gender: string = null;
    pageUrl: string = null;

    ngOnInit(): void {
        this.gender = this.route.snapshot.paramMap.get('gender');
        this.pageUrl = this.gender === 'male' ? environment.safetyMalePageUrl : environment.safetyPageUrl;
    }
}
