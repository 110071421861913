import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-card-separator',
  templateUrl: './card-separator.component.html',
  styleUrls: ['./card-separator.component.css']
})
export class CardSeparatorComponent implements OnInit, OnChanges {

  @Input() data: any;
  @Input() type: any;
  public info = [];
  public days: any;
  public dailyIncentive = false;
  public index = 0;
  public timeSlotsDisplay: any;
  public contentData: any;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data.currentValue) {
      this.getDataContent();
    }
  }

  ngOnInit() {
  }

  getDataContent() {
    if (this.type !== 'Daily') {
      this.contentData = Object.keys(this.data)[0].split(',');
      if (this.contentData && this.contentData.length > 1) {
        this.days = this.contentData[0] + ' - ' + this.contentData[this.contentData.length - 1];
      } else {
        this.days = '' + this.contentData;
      }
      const goals = Object.keys(this.data)[0];
      for (const goal in this.data[goals]) {
        if (goal) {
          const timeString = this.getTimeStrings(this.data[goals][goal].timeSlot).join(', ');
          let tempGoal = {};
          tempGoal = {header : this.days, data : this.data[goals][goal], timeslots: timeString};
          this.info.push(tempGoal);
        }
      }
    } else {
      const goals = Object.keys(this.data)[0];
      for (const goal in this.data[goals]) {
        if (goal) {
          const timeString = this.getTimeStrings(this.data[goals][goal].timeSlot).join(', ');
          let tempGoal = {};
          tempGoal = {header : timeString, data : this.data[goals][goal]};
          this.info.push(tempGoal);
        }
      }
    }
  }

  getTimeStrings(timeslots) {
    const timeSlotsToDisplay = [];
    for (const times in timeslots) {
      if (times && timeslots[times]) {
        let from = timeslots[times].fromTime ? timeslots[times].fromTime : null;
        let to = timeslots[times].toTime ? timeslots[times].toTime : null;
        if (from && to) {
          from = this.returnTimeStamp(from);
          to = this.returnTimeStamp(to);
          timeSlotsToDisplay.push(from + ' - ' + to);
        }
      }
    }
    return timeSlotsToDisplay;
  }

  returnTimeStamp(time) {
    const timeArray = time.split(':');
    const hour = parseInt(timeArray[0], 10);
    if (hour === 12) {
        return time + ' PM';
    }
    if (hour <= 12) {
      return time + ' AM';
    } else {
      timeArray[0] -= 12;
      return timeArray.join(':') + ' PM';
    }
    // const timeArray = time.split(':');
    // if (parseInt(timeArray[0], 10) <= 12) {
    //   return time + ' AM';
    // } else {
    //   timeArray[0] -= 12;
    //   return timeArray.join(':') + ' PM';
    // }
  }
}
