import { Component, OnInit } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrService } from 'ngx-toastr';
import { TimeUtils } from '../../time-utils';
import { SuperSaverWeekService } from '../super-saver-week-service';

@Component({
  selector: 'app-ssw',
  templateUrl: './super-saver-week-component.html',
  styleUrls: ['./super-saver-week-component.css']
})

export class SuperSaverWeekComponent implements OnInit {

  public configs: any
  public superSaverIcon: string
  public brandIcon: string
  public backgroundImage: string
  public sswIsActive: boolean
  public sswStartDate: Date
  public sswEndDate: Date
  public isOneDayReamining: boolean
  public reaminingTime: Object
  public nextSswStartDate: Date
  public cashBackUpto: number
  public sharingText: string
  public status: string
  public statusImagePath: string
  public prize: Object
  public tnc: string[]
  public postSevenDays: boolean;
  timeUtils: TimeUtils
  service: SuperSaverWeekService;
  public loaded: boolean;
  private spinnerService: Ng4LoadingSpinnerService;
  public showTemplate = false
  public deviceType: String
  public showNotifyButton: Boolean = true;

  constructor(timeUtils: TimeUtils, service: SuperSaverWeekService, spinnerService: Ng4LoadingSpinnerService, private toast: ToastrService) {
    this.spinnerService = spinnerService
    this.timeUtils = timeUtils
    this.service = service
  }

  ngOnInit(): void {
    this.deviceType = this.getDeviceType()
    this.spinnerService.show()
    this.service.getSuperSaverWeekConfigs()
      .subscribe(res => {
        this.configs = res;
        this.updateValues()
      }, () => {
        this.spinnerService.hide()
        alert('Something went wrong. Please try again.');
      });
  }

  getDeviceType(): String {
    if (navigator.userAgent.toLowerCase().match("ios")) {
      return "Ios"
    }
    if (navigator.userAgent.toLowerCase().match("android")) {
      return "Android"
    }
    if (navigator.userAgent.toLowerCase().match("mac")) {
      return "Mac"
    }
    return ""
  }

  updateValues() {
    this.sswStartDate = new Date(this.configs.startDate)
    this.sswEndDate = new Date(this.configs.endDate)
    this.nextSswStartDate = new Date(this.configs.nextSswStartDate)

    this.sswIsActive = isSuperSaverWeekActive(this.sswStartDate, this.sswEndDate)


    let daysReamining = this.timeUtils.getDaysBetweenTwoDays(new Date(), this.sswStartDate)

    this.isOneDayReamining = daysReamining === 1
    this.superSaverIcon = '/assets/ssw/ssw.svg'
    this.cashBackUpto = this.configs.cashBackUpto
    this.prize = this.configs.prize
    this.tnc = this.configs.tnc
    this.sharingText = this.configs.sharingText
    this.postSevenDays = this.timeUtils.getDaysBetweenTwoDays(this.sswEndDate, new Date()) < 8

    if (this.isOneDayReamining) {
      setInterval(() => {
        this.reaminingTime = this.timeUtils.getElapsedTimeFrom(this.sswStartDate)
        this.spinnerService.hide()
        this.showTemplate = true
      }, 1000)
      this.setInActiveAssets()
    } else if (this.sswIsActive) {
      this.setActiveAssets();
      this.spinnerService.hide()
      this.showTemplate = true
    } else if (!this.sswIsActive) {
      this.setInActiveAssets()
      this.spinnerService.hide()
      this.showTemplate = true
    }
  }

  share = () => {
    if (this.deviceType == "Android") {
      shareAndroid(this.sharingText)
    }
    else {
      shareIos(this.sharingText)
    }
  }
  bookRide = () => {
    if (this.deviceType == "Android") {
      bookAndroid()
    }
    else {
      bookIos()
    }
  }

  notifyMe = () => {
    this.toast.success('', 'Thanks for your interest! We would love to notify you.');
    this.showNotifyButton = false
    if (this.deviceType == "Android") {
      notifyAndroid()
    }
    else {
      noifyIos()
    }
  }

  setInActiveAssets() {
    this.brandIcon = '/assets/ssw/brand-inactive.svg'
    this.backgroundImage = '/assets/ssw/background-inactive.svg'
    this.status = "Ended"
    this.statusImagePath = "/assets/ssw/ended.svg"
  }

  setActiveAssets() {
    this.brandIcon = '/assets/ssw/brand-active.svg'
    this.backgroundImage = '/assets/ssw/background-active.svg'
    this.status = "Live now"
    this.statusImagePath = "/assets/ssw/live.svg"
  }
}

function isSuperSaverWeekActive(startDate: Date, endDate: Date): boolean {
  var today = new Date();
  endDate.setHours(23, 59, 59, 0)
  return (today.valueOf() >= startDate.valueOf() && today.valueOf() <= endDate.valueOf())
}

function notifyAndroid() {
  window['RapidoNative'].notifyMe();
}

function noifyIos() {
  if (!(typeof (window as any).webkit === 'undefined' || (window as any).webkit === null)) {
    (window as any).webkit.messageHandlers.notify.postMessage(true);
  }
}

function shareAndroid(text: String) {
  window['RapidoNative'].shareRide(text);
}

function shareIos(text: String) {
  if (!(typeof (window as any).webkit === 'undefined' || (window as any).webkit === null)) {
    (window as any).webkit.messageHandlers.share.postMessage(text);
  }
}

function bookAndroid() {
  var testVal = 'rapido://home_bike_taxi';
  window['RapidoNative'].bookARide(testVal);
}

function bookIos() {
  var testVal = 'rapido://home_bike_taxi';
  if (!(typeof (window as any).webkit === 'undefined' || (window as any).webkit === null)) {
    (window as any).webkit.messageHandlers.bookride.postMessage(testVal);
  }
}