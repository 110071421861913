import {Component, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'rideGuarantee',
    templateUrl: './rideGuarantee-webview.component.html',
    styleUrls: ['./rideGuarantee-webview.component.css']
})

export class RideGuaranteeWebview implements OnInit {
    pageUrl: string = null;
    ngOnInit(): void {
        this.pageUrl = environment.rideGuaranteePageUrl;
    }
}
