<div class="height-100">
  <app-download-app></app-download-app>
  <app-info-header [header]="header"></app-info-header>

  <div *ngFor="let card of cardData">
    <app-card-separator [type]="data.incentiveType" [data]="card"></app-card-separator>
  </div>

  <div class="margin-top-20"></div>

</div>
