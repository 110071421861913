<div class="wrapper">
  <div class="parent">
    <div class="banner">
      <div class="imageDiv"></div>
    </div>
    <div class="container margin-10-0">
      <div class="singleRow display-center">
        <p class="perks-text"><strong>Perks of being a Rapido Captain</strong></p>
      </div>
      <hr class="hr-styled">
    </div>

    <div class="singleRow margin-bottom-32">
      <div style="padding-left: 64px">
        <div class="svgIcon flexible-timing"></div>
      </div>
      <div class="col-8 padding-0 font-18 padding-text">
        Flexible Timings
      </div>
    </div>
    <div class="singleRow margin-bottom-32">
      <div style="padding-left: 64px">
        <div class="svgIcon faster-payments"></div>
      </div>
      <div class="col-8 padding-0 font-18 padding-text">
        Faster Payments
      </div>
    </div>
    <div class="singleRow margin-bottom-32">
      <div style="padding-left: 64px">
        <div class="svgIcon incentives-rewards"></div>
      </div>
      <div class="col-8 padding-0 font-18 padding-text">
        Incentives and rewards
      </div>
    </div>
    <div class="singleRow margin-bottom-32">
      <div style="padding-left: 64px">
        <div class="svgIcon insurance-coverage"></div>
      </div>
      <div class="col-8 padding-0 font-18 padding-text">
        Insurance coverage
      </div>
    </div>

    <div class="singleRow need-text">
      <label><strong>All you need is a Bike, DL and RC !</strong></label>
    </div>

    <div class="singleRow checkbox-alignment">
      <div class="col-1">
        <input [(ngModel)]="optedIn" type="checkbox" id="optIn">
      </div>
      <div class="col-10 text-alignment">
        <label for="optIn">
            I accept to receive Rapido booking communication messages on WhatsApp.
        </label>
      </div>
    </div>
    <div [ngStyle]="{'cursor' : optedIn ? 'pointer' : 'not-allowed'}" [class.button-whatsapp-disabled]="!optedIn" class="singleRow button-whatsapp" (click)="onWhatsappClick()">
      <div class="whatsapp-text">
          <div class="icon-sizing" [class.imageDiv-whatsapp]="optedIn" [class.imageDiv-whatsapp-white]="optedIn" [class.disabled-whatsapp]="!optedIn">
          </div>
          Become a Captain
      </div>
    </div>
  </div>
  <div>
  </div>
</div>


