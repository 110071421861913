import { Renderer2, Component, OnInit, Inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';



@Component({
  selector: 'app-book-rapido-via-whatsapp',
  templateUrl: './book-rapido-via-whatsapp.component.html',
  styleUrls: ['./book-rapido-via-whatsapp.component.css']
})
export class BookRapidoViaWhatsappComponent implements OnInit {

  public optedIn = true;
  constructor(
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document
  ) { }

  ngOnInit() {
    const script = this._renderer2.createElement('script');
    // script.type = `application/ld+json`;
    script.text = `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '857628661017736');
    fbq('track', 'PageView');`;

    const noscript = this._renderer2.createElement('noscript');
    // noscript.type = `application/ld+json`;
    noscript.text = `<img height=“1” width=“1" style=“display:none”
    src=“https://www.facebook.com/tr?id=857628661017736&ev=PageView&noscript=1"/>`;

    this._renderer2.appendChild(this._document.body, script);
    this._renderer2.appendChild(this._document.body, noscript);
  }

  onWhatsappClick() {
    if (this.optedIn) {
      window.open(
        `https://api.whatsapp.com/send?phone=917411412413&text=Hello!%20I%27m%20interested%20in%20booking%20Rapido%20through%20WhatsApp.`
        );
    }
  }

}
