import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
    selector: 'rating-info',
    templateUrl: './rating-info-webview.component.html',
    styleUrls: ['./rating-info-webview.component.css']
})

export class RatingInfoWebview implements OnInit {
    pageUrl: string = null;
    ngOnInit(): void {
        this.pageUrl = environment.ratingPageUrl
    }
}
