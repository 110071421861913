import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Renderer2 } from '@angular/core';

interface IValueProp {
  title: string;
  description: string;
  iconUrl: string;
  darkIconUrl?: string;
}

interface ILink {
  displayText: string;
  url: string;
  leadingIcon: string;
  trailingIcon: string;
  darkLeadingIcon?: string;
  darkTrailingIcon?: string;
}

interface IConfig {
  assetUrl: string;
  darkAssetUrl?: string;
  titleText: string;
  description: string;
  valuePropositions: IValueProp[];
  link: ILink;
}

@Component({
  selector: 'app-generic-template',
  templateUrl: './generic-template.component.html',
  styleUrls: ['./generic-template.component.css']
})
export class GenericTemplateComponent implements OnInit {

  campaign: string;
  hasVpIcons = false;
  hasLink = false;
  genericTemplateConfig: string;
  error = false;
  configuration: IConfig;
  darkMode = false;
  showScrollIndication = false;
  constructor(
    private route: ActivatedRoute,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.error = false;
    try {
      this.genericTemplateConfig = JSON.parse(environment.genericTemplateConfig);
      this.route.queryParams.subscribe(params => {
        if (params) {
          params.darkMode && params.darkMode === 'true' ? this.enableDarkMode() : "";
          this.campaign = params.campaignName || "";
          if (this.campaign && this.genericTemplateConfig && this.genericTemplateConfig[this.campaign]) {
            this.configuration = this.genericTemplateConfig[this.campaign];
          } else {
            this.error = true;
            return;
          }
        } else {
          this.error = true;
          return;
        }
      })

      var self = this;

      document.addEventListener("DOMContentLoaded", function() {
        const text = self.configuration.titleText;
        var titleElement = document.getElementById("title");
        titleElement.innerHTML = text;

        function isScrollable() {
          return document.body.scrollHeight > window.innerHeight;
        }

        function toggleScrollIndicator() {
          if (isScrollable()) {
            self.showScrollIndication = true;
            window.addEventListener('scroll', () => {
              setTimeout(() => {
                self.showScrollIndication = false;
                window.removeEventListener('scroll', () => {});
              }, 500);
            });
          } else {
            self.showScrollIndication = false;
          }
        }

        toggleScrollIndicator();
      });

      this.checkIcons();
      this.checkLink();

    } catch (e) {
      this.error = true;
    }
  }

  enableDarkMode() {
    this.darkMode = true;
    this.renderer.addClass(document.body, 'dark-mode');
  }

  checkIcons() {
    if (this.configuration && this.configuration.valuePropositions && this.configuration.valuePropositions.length) {
      this.hasVpIcons = true;
      for (let proposition of this.configuration.valuePropositions) {
        if (!proposition.iconUrl || proposition.iconUrl == "") {
          this.hasVpIcons = false;
          break;
        }
      }
    }
  }

  checkLink() {
    if (this.configuration && this.configuration.link && this.configuration.link.url) {
      this.hasLink = true;
    }
  }

  renderTitle(title) {
    var newText = title.replace(/\n/g, "<br>");
    return newText;
  }
}
