import { Component } from '@angular/core';

@Component({
  selector: 'app-does-donts',
  templateUrl: './does-donts.component.html',
  styleUrls: ['./does-donts.component.css']
})
export class DoesDontsComponent{
    
    constructor() { }

}
